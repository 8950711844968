import React, { useEffect, useState } from "react";
import Table from "../../../components/ui/Table";
import { isMultiYear } from "../../../services/defaultSettings";
import { printFullDate } from "../../../utils/time";

import { getYearPlaceHolder } from "../../../services/yearSevices";
import { printIdOfOptions } from "../../../utils/ar";
import http from "../../../services/httpServices";

const ShowUser = ({ user }) => {
    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => row.id,
        },
        {
            name: "عدد مرات تغير الباسورد",
            reorder: true,
            selector: (row) => row.password_reset_count,
        },
        {
            name: "الاسم الكامل",
            reorder: true,
            selector: (row) => row.full_name,
        },
    ];
    columns = [
        ...columns,
        {
            name: "رقم الهاتف",
            reorder: true,
            selector: (row) => row.phone,
        },
        {
            name: "رقم هاتف ولي الأمر",
            reorder: true,
            selector: (row) => row.father_phone,
        },
        {
            name: "المحافظة",
            reorder: true,
            selector: (row) => printIdOfOptions(governments, row.government_id),
            sortable: true,
            sortField: "phone",
        },
    ];
    columns = [
        ...columns,
        {
            name: "البريد الإلكتروني",
            reorder: true,
            selector: (row) => row.email,
        },
    ];
    if (isMultiYear) {
        columns.push({
            name: "العام الدراسي",
            reorder: true,
            selector: (row) => row.year,
            format: (row) => getYearPlaceHolder(row.year),
        });
    }
    columns.push({
        name: "تاريخ انشاء الحساب",
        reorder: true,
        selector: (row) => row.created_at,
        format: (row) => printFullDate(row.created_at),
    });
    const [governments, setGovernments] = useState([]);

    async function getGovernments() {
        const { data } = await http.get("/api/governments");
        setGovernments(data);
    }
    useEffect(() => {
        getGovernments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Table columns={columns} data={user} />;
};

export default ShowUser;

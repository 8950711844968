import React, { useEffect, useState } from "react";
import Heading from "../../components/ui/Heading";
import Container from "../../components/ui/Container";
import http from "../../services/httpServices";
import CourseFollowUpSection from "../../components/ui/CourseFollowUpSection";
import ProgressCircle from "../../components/ui/ProgressCircle";
import CenterIcon from "../../components/ui/CenterIcon";
import Form from "../../components/form/elements/Form";
import InputField from "../../components/form/elements/InputField";
import { handleFormErrors, handleInputChange } from "../../services/formServices";
import Button from "../../components/ui/Button";

const ParentDashboard = () => {
    const [followUpData, setFollowUpData] = useState({ courses: [] });
    const [data, setData] = useState({ phone: "", father_phone: "" });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const getParentDashboardData = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        try {
            await http.get("/sanctum/csrf-cookie");
            const result = await http.post("api/parent_dashboard", {
                phone: parseInt(data["phone"]),
                father_phone: parseInt(data["father_phone"]),
            });
            setFollowUpData(result.data);
        } catch (error) {
            handleFormErrors(error, setIsLoading, setErrors);
        }
    };
    useEffect(() => {
        // getParentDashboardData();
    }, []);
    return (
        <Container>
            <div className="flex flex-col items-center justify-center h-fit gap-10">
                <Heading title="متابعة" subtitle="ولي الأمر" />
                <div className="text-center w-full text-lg md:text-2xl lg:max-w-[800px]">
                    يمكنك من خلال هذه الصفحة متابعة نجلك ومتابعة تقدمه الدراسي و مراجعه اختباراته و
                    واجباته
                </div>
            </div>
            {!followUpData.full_name ? (
                <Form className="flex-center-both gap-5">
                    <div className="space-y-5 p-8 rounded-md shadow-xl">
                        <InputField
                            placeholder="رقم هاتف نجلك"
                            id="phone"
                            onChange={handleInputChange}
                            data={data}
                            errors={errors}
                            setData={setData}
                        />
                        <InputField
                            placeholder="رقم هاتفك"
                            id="father_phone"
                            onChange={handleInputChange}
                            data={data}
                            errors={errors}
                            setData={setData}
                        />
                        <Button
                            color="rose"
                            className="w-full"
                            onClick={getParentDashboardData}
                            isLoading={isLoading}
                        >
                            ابدأ المتابعة
                        </Button>
                    </div>
                </Form>
            ) : (
                ""
            )}
            {followUpData.full_name ? <StatisticsSection followUpData={followUpData} /> : ""}
        </Container>
    );
};

const StatisticsSection = ({ followUpData }) => {
    return (
        <section className={`space-y-10 `}>
            <div>
                <div className="font-w-bold text-center font-h1 pt-5 pb-10 flex-center-both space-x-3 space-x-reverse">
                    <CenterIcon icon="arcticons:questionnaire-star" className="text-rose-500" />
                    <span>
                        تفاصيل <span className="text-rose-500">احصائيات نجلك بالكورسات !</span>
                    </span>
                    <CenterIcon icon="arcticons:questionnaire-star" className="text-rose-500" />
                </div>
            </div>
            {followUpData.courses.length > 0
                ? followUpData.courses.map((course) => (
                      <>
                          <CourseFollowUpSection course={course} />
                      </>
                  ))
                : ""}
        </section>
    );
};

const OverviewStatistics = () => {
    return (
        <div>
            <div className="font-w-bold text-center font-h1 pt-5 pb-10 flex-center-both space-x-3 space-x-reverse">
                <CenterIcon icon="arcticons:questionnaire-star" className="text-cyan-500" />
                <span>
                    احصائيات إجمالية لنجلك في{" "}
                    <span className="text-rose-500">الكورسات اللي هو مشترك فيها</span>{" "}
                </span>
                <CenterIcon icon="arcticons:questionnaire-star" className="text-cyan-500" />
            </div>
            <div className="w-full flex items-center justify-evenly">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 items-start">
                    <ProgressCircle
                        color="rose"
                        unit="فيديو"
                        title="عدد الفيديوهات نجلك شافها"
                        value={5}
                        max={10}
                        textsIndex={3}
                    />
                    <ProgressCircle
                        color="cyan"
                        unit="امتحان و واجب"
                        title="عدد الامتحانات و الواجبات نجلك خلصها"
                        value={5}
                        max={10}
                        textsIndex={2}
                    />
                    <ProgressCircle
                        color="purple"
                        unit={false}
                        title="متوسط نتائج نجلك"
                        value={5}
                        max={10}
                        textsIndex={1}
                    />
                </div>
            </div>
        </div>
    );
};

export default ParentDashboard;

import { Icon } from "@iconify/react";
import React from "react";

import FooterLink from "./components/FooterLink";
import { ReactComponent as FaceIcon } from "../../assets/khaled-imagery/facebook_icon.svg";
import tubeIcon from "../../assets/khaled-imagery/youtube_icon.png";
import FooterImg from "../../assets/khaled-imagery/FooterImg.png";
import starsbg from "../../assets/Group8.png";

const Footer = () => {
    return (
        <div className="footer relative overflow-hidden bg-sakrBlue-900 py-20 clr-white flex-center-both flex-col space-y-6 w-full">
            <img
                src={FooterImg}
                alt="Tube"
                className="absolute animate-pulse bottom-5 left-0 rotate-[15deg] opacity-20"
            />
            <div
                className="inset-0 w-full h-full z-0 absolute opacity-40 smooth"
                style={{
                    backgroundImage: "url(" + starsbg + ")",
                    backgroundRepeat: "repeat",
                }}
            ></div>
            <img
                src={FooterImg}
                alt="Tube"
                className="absolute animate-pulse delay-75 bottom-5 right-0 -rotate-[15deg] opacity-20"
            />
            <div className="flex-center-both space-x-5 space-x-reverse">
                <FooterLink
                    link="https://www.youtube.com/channel/UClvucpj2qZ3OUM2not9YVpA"
                    icon={<img className="w-14" src={tubeIcon} alt="youtube-sakr" />}
                />
                <FooterLink
                    link="https://www.facebook.com/Mr-Khaled-Sakr-103575551472868"
                    icon={<FaceIcon className="w-14 fill-transparent" />}
                />
            </div>
            <div className="h-1 bg-slate-800 rounded-md w-2/3 sm:w-1/3 "></div>
            <div className="flex-center-both space-x-5 space-x-reverse px-5">
                <span className="font-h2 ">
                    <Icon icon="emojione-v1:beating-heart" />
                </span>
                <span className="text-slate-200 text-center shrink">
                    تم صنع هذه المنصة بهدف تهيئة الطالب لـ كامل جوانب الثانوية العامة و ما بعدها
                </span>
                <span className="font-h2">
                    <Icon icon="emojione-v1:beating-heart" />
                </span>
            </div>
            <div className="en text-center font-com space-x-2 opacity-80 px-5 flex flex-wrap flex-center-both">
                <span className="font-w-bold space-x-1">
                    <span className="text-redMelon-600">&#60;</span>
                    <span className="text-redMelon-300">Developed By</span>
                    <span className="text-redMelon-600">&#62;</span>
                </span>
                <span>
                    <a
                        href="https://www.facebook.com/Om4rS4Ieh/"
                        className="bg-sakrBlue-700 hover-shadow smooth px-2 py-2 rounded-md"
                    >
                        Omar
                    </a>
                    <span>,</span>
                    <a
                        href="https://www.facebook.com/emad.sharf.16"
                        className="bg-sakrBlue-700 hover-shadow smooth px-2 py-2 rounded-md"
                    >
                        Emad
                    </a>
                </span>
                <span className="font-w-bold space-x-1">
                    <span className="text-redMelon-600">&#60;</span>
                    <span className="text-redMelon-300">
                        All Copy Rights Reserved @{new Date().getFullYear()}
                    </span>
                    <span className="text-redMelon-600">&#62;</span>
                </span>
            </div>
            <div className="en">Powered by Shakepay</div>
            {/* <div className="text"></div> */}
        </div>
    );
};

export default Footer;

import React, { useState } from "react";
import Button from "./Button";
import CenterIcon from "./CenterIcon";
import Container from "./Container";
import FollowUpTableCard from "./FollowUpTableCard";

const CourseFollowUpSection = ({ course }) => {
    const [showWeeks, setShowWeeks] = useState(false);
    return (
        <div className="border-b border-secondary-container flex-center-both flex-col pt-10 shadow-md rounded-xl px-4">
            <OwnTitle
                showWeeks={showWeeks}
                setShowWeeks={setShowWeeks}
                isToShowWeeks={course.sections.length}
                title={course.name}
            />

            <Container className="flex-center-both w-full gap-8 flex-col">
                {/* <p className="text-center font-h2">{weekDescription}</p> */}

                {course.sections.length > 0 ? (
                    <>
                        {!showWeeks ? (
                            <Button
                                className="flex-center-both space-x-2 font-w-bold bg-third-container border-secondary-container clr-text-primary space-x-reverse gap-3 smooth group border "
                                onClick={() => setShowWeeks(!showWeeks)}
                            >
                                <span>عرض المحتوى</span>
                                <CenterIcon nY="0" icon="eva:arrow-ios-downward-outline" />
                                {/* <CenterIcon icon="svg-spinners:3-dots-fade" /> */}
                            </Button>
                        ) : (
                            <>
                                <div className="flex flex-col gap-2 w-full">
                                    {course.sections.map((section) => (
                                        <FollowUpTableCard key={section.id} section={section} />
                                    ))}
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    ""
                )}
            </Container>
        </div>
    );
};

const OwnTitle = ({ showWeeks, setShowWeeks, title, isToShowWeeks = true }) => {
    return (
        <>
            <div className="w-full flex justify-between">
                <div className="relative flex h-fit items-center justify-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="231"
                        height="101"
                        viewBox="0 0 231 101"
                        fill="none"
                        className="absolute right-0 -z-10"
                    >
                        <ellipse
                            cx="115.5"
                            cy="50.5"
                            rx="115.5"
                            ry="50.5"
                            className={"fill-rose-200 dark:fill-rose-800 smooth"}
                        />
                    </svg>
                    <h1 className=" md:font-w-bold text-3xl md:text-4xl pr-2 ">
                        {title}
                        {/* <span className="font-w-bold">"{weekStatus}"</span> */}
                    </h1>
                </div>
                <div className="pt-2">
                    {isToShowWeeks ? (
                        <Button
                            className="flex-center-both space-x-2 font-w-bold space-x-reverse border-rose-500 text-rose-500 smooth group bg- border underline"
                            onClick={() => setShowWeeks(!showWeeks)}
                        >
                            {!showWeeks ? (
                                <span className="">عرض المحتوى</span>
                            ) : (
                                <span className="">إخفاء المحتوى</span>
                            )}
                            <CenterIcon
                                className={`transform smooth text-rose-500 ${
                                    showWeeks ? "pb-1 -rotate-180 " : "pt-0.5 rotate-0"
                                }`}
                                icon="bxs:up-arrow"
                            />
                        </Button>
                    ) : (
                        <div className="font-w-bold bg-third-container border-secondary-container smooth rounded-md border clr-text-primary px-5 py-2">
                            <div>لم يتم نزول المحتوى في هذا الكورس حتى الآن</div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default CourseFollowUpSection;

import React from "react";

import Container from "../../components/ui/Container";
import CourseCard from "../../components/ui/CourseCard";

// import year1 from "../../../assets/year-2.jpeg";
// import year2 from "../../assets/khaled-imagery/year2.svg";
// import year3 from "../../assets/khaled-imagery/year3.svg";
// import year1 from "../../assets/khaled-imagery/year1.svg";
import yearImg from "../../assets/khaled-imagery/year.png";
import { ReactComponent as WaterMelonHeaderIcon } from "../../assets/khaled-imagery/HeaderWaterMelonIcon.svg";
import starsbg from "../../assets/Group8.png";

import bag from "../../assets/bag.svg";

const YearsSection = ({ title = false }) => {
    // const { token } = useContext(AuthContext);
    return (
        <section className="min-h-screen my-10">
            <div className="py-5 z-10 relative flex-center-both md:flex-row flex-col">
                <WaterMelonHeaderIcon className="relative z-10" />
                <div className="flex flex-col font-rady">
                    <p className="text-stroke-transparent relative -z-10 -mr-5 font-w-bold text-4xl sm:text-5xl text-transparent">
                        السنين الدراسية
                    </p>
                    <h1 className="text-4xl sm:text-5xl font-w-bold font-rady">
                        السنين
                        <span className="text-redMelon-500"> الدراسية </span>
                    </h1>
                </div>
            </div>
            <div className="relative mb-20 overflow-hidden  py-20 rounded-md" id="courses">
                <div className="px-2 md:px-10">
                    <Container className="relative z-10">
                        <div
                            className={`g-teal-400 smooth clr-text-primary drk:bg-teal-800 bg-opacity-50 dark:bg-opacity-50 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10`}
                        >
                            <CourseCard
                                name="بطيختي"
                                nickName="الصغننة"
                                yearDescription={["كل محتوي", "طلبة الصف الأول الثانوي"]}
                                isYear={1}
                                isStaticPicture={yearImg}
                            />
                            <CourseCard
                                name="بطيختي"
                                nickName="المتوسطة"
                                yearDescription={["كل محتوي", "طلبة الصف الثاني الثانوي"]}
                                isYear={2}
                                isStaticPicture={yearImg}
                            />
                            <CourseCard
                                name="بطيختي"
                                nickName="الكبيرة"
                                yearDescription={["كل محتوي", "طلبة الصف الثالث الثانوي"]}
                                isYear={3}
                                isStaticPicture={yearImg}
                            />
                        </div>
                    </Container>
                </div>
            </div>
        </section>
    );
};

export default YearsSection;

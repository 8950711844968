import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import MainSection from "../sections/home/MainSection";
import { ScrollingProvider } from "../context/ScrollingContext";
import { getYearPlaceHolder } from "../services/yearSevices";
import CoursesSection from "../sections/home/CoursesSection/CoursesSection";
import AboutSection from "../sections/home/AboutSection";
import auth from "../services/authServices";
import Categorie from "../sections/home/CoursesSection/Categorie";
import SectionHead from "../components/ui/SectionHead";
import Button from "../components/ui/Button";
import AuthContext from "../context/AuthContext";
import CenterIcon from "../components/ui/CenterIcon";
import LoadingIcon from "../components/ui/LoadingIcon";

const Year = () => {
    const { id } = useParams();

    const token = auth.getToken();

    const {
        subscribedCourses,
        getSubscribedCourses,
        isSubscribedCoursesFetchingLoading,
        isSubscribedCoursesFetchingError,
        handleSubscribedCourses,
    } = useContext(AuthContext);
    useEffect(() => {
        if (!subscribedCourses && token) {
            getSubscribedCourses();
        }
    }, [subscribedCourses]);

    return (
        <>
            {token ? (
                <>
                    {/* <div className="flex-center-both w-full">
                        <div className="relative z-10 font-h1 py-20 saturate-50">
                            <Button
                                color="rose"
                                className="px-20 py-4"
                                element="Link"
                                to="/community/community_categories"
                            >
                                منتدى طلبة خالد صقر
                            </Button>
                        </div>
                    </div> */}
                    <ScrollingProvider>
                        <SectionHead
                            title={"اشتراكاتك"}
                            withRefreshBtn={true}
                            callback={() => handleSubscribedCourses()}
                        />
                    </ScrollingProvider>
                    {isSubscribedCoursesFetchingError ? (
                        <div className="font-w-bold rounded-md bg-third-container clr-text-primary border border-secondary-container smooth p-5 flex-center-both space-x-3 space-x-reverse">
                            <CenterIcon icon="bxs:error" className="font-h1 text-rose-500" />
                            <span>حدث خطأ اثناء تحميل الكورسات</span>
                        </div>
                    ) : isSubscribedCoursesFetchingLoading ? (
                        <div className="font-w-bold rounded-md bg-third-container clr-text-primary border border-secondary-container smooth p-5 flex-center-both space-x-3 space-x-reverse">
                            <span className="font-h1">
                                <LoadingIcon />
                            </span>
                            <span>يتم الآن تحميل اشتراكاتك</span>
                        </div>
                    ) : (
                        <Categorie
                            coursesList={subscribedCourses}
                            noCoursesPlaceholder="انت غير مشترك بأي كورس حتى الآن!"
                        />
                    )}
                </>
            ) : (
                <>
                    <MainSection title={getYearPlaceHolder(id)} />
                    <ScrollingProvider>
                        <AboutSection />
                    </ScrollingProvider>
                </>
            )}

            <CoursesSection title={getYearPlaceHolder(id)} year={id} />
        </>
    );
};

export default Year;

import React, { useContext, useEffect, useState } from "react";

import MainSection from "../sections/home/MainSection";
import { ScrollingProvider } from "../context/ScrollingContext";

import YearsSection from "../sections/home/YearsSection";

import AboutSection from "../sections/home/AboutSection";
import { useLocation, useNavigate } from "react-router-dom";
import auth from "../services/authServices";
import AuthContext from "../context/AuthContext";
import CTASakr from "../sections/home/CTASakr";

const Home = () => {
    // const [isAuth, setIsAuth] = useState(false);
    const { user, isNavigated, setIsNavigated } = useContext(AuthContext);
    // if (auth.isAuth()) {
    //     setIsAuth(true);
    // }
    const navigate = useNavigate();
    const location = useLocation();
    const token = auth.getToken();
    if (token) {
        if (user.year) {
            navigate(`/years/${user.year}`);
        }
    }
    useEffect(() => {
        if (token && !isNavigated) {
            if (user.year) {
                setIsNavigated(true);
                navigate(`/years/${user.year}`);
            }
        }
    }, [user, location.pathname]);
    return (
        <>
            <MainSection />
            <ScrollingProvider>
                <AboutSection />
            </ScrollingProvider>

            {/* <CoursesSection /> */}
            <YearsSection />
            <CTASakr />
        </>
    );
};

export default Home;

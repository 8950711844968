import React, { useContext, useState } from "react";
import AuthContext from "../../context/AuthContext";
import Container from "../../components/ui/Container";
import CtaImg from "../../assets/khaled-imagery/CtaImage.png";
import TextDecore from "../../assets/khaled-imagery/textDecore.png";
import { Link } from "react-router-dom";
import waterMelonicon from "../../assets/khaled-imagery/waterMelonIcon.png";

const CTASakr = () => {
    const { token } = useContext(AuthContext);
    const [ctaTransform, setCtaTransform] = useState("translate(0,0)");
    const onMouseMove = (e) => {
        let mainSection = document.getElementById("cta_section");
        let mainSectionHeight = mainSection.clientHeight;
        let mainSectionWidth = mainSection.clientWidth;

        let ammountToBeMoved = 20;

        let xPercentage = (e.pageX * 1.5) / mainSectionWidth - 1;
        let yPercentage = (e.pageY * 1.5) / mainSectionHeight - 1;
        // console.log(xPercentage, yPercentage);

        let x = xPercentage * ammountToBeMoved;
        let y = yPercentage * ammountToBeMoved;

        setCtaTransform(`translate(${x}px,${y}px)`);
    };
    return (
        <section
            id="cta_section"
            className="negative-nav-margin  smooth clr-text-primary relative overflow-hidden"
            onMouseMove={onMouseMove}
        >
            <div className="md:h-screen min-h-screen">
                <Container className="h-full relative z-10 font-fs pt-32 md:pt-0 pb-20 md:pb-0">
                    <div className="w-full flex md:flex-row flex-col h-full space-y-20 md:space-y-0 md:px-32">
                        <div className="md:basis-1/2 basis-full h-full flex-center-both">
                            <div className="w-full h-full flex-center-both max-w-md pt-12">
                                <div className="w-full h-full flex-center-both">
                                    <img
                                        src={CtaImg}
                                        alt={"Cta"}
                                        className="max-h-full smooth"
                                        style={{ transform: ctaTransform }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className=" md:basis-1/2 basis-full h-full flex-center-both">
                            <div className="flex flex-col md:items-start items-center justify-center space-y-6 md:space-y-0 font-kuf">
                                <h1 className="font-bigmax font-rady text-redMelon-500 text-stroke font-w-bold relative smooth flex">
                                    <div className="relative">
                                        <div className="-top-14 -left-14 w-full h-full z-0 absolute">
                                            <img
                                                src={TextDecore}
                                                alt={"bgchem"}
                                                className="h-full w-full"
                                            />
                                        </div>
                                        <p className="relative z-10">وبعدين يـــ</p>
                                    </div>
                                </h1>
                                <div className="flex flex-col font-rady text-center md:text-right w-3/4 pt-5 md:items-start items-center justify-center">
                                    <h2 className="text-2xl">
                                        تيم الأسطورة كبر عشانك يـ بطيخة منك ليه .. و بقي معاك علي
                                        مدار اليوم خطوة بـ خطوة عشان توصل لحلم ال ٦٠ وتقفل .. ملكش
                                        حجة{" "}
                                    </h2>
                                </div>
                                <div className="flex flex-col space-y-5 pt-10">
                                    {!token ? (
                                        <div className="btn-holder">
                                            <Link
                                                className="btn btn-4 pt-2 pb-1 pr-1 pl-1 min-w-[270px] hover:scale-105 smooth group  hover-border-7"
                                                to={`${token ? "/me/user/courses" : "/register"}`}
                                            >
                                                <span className=" px-5 py-3 font-semibold bg-[#63D497] group-hover:shadow-md smooth text-black flex-center-both gap-4">
                                                    <img src={waterMelonicon} alt="Sakr-Cta-Icon" />
                                                    أستر نفــسك بأكونت
                                                </span>
                                            </Link>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="py-10"></div>
        </section>
    );
};

export default CTASakr;

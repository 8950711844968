import React, { useEffect, useState } from "react";
import modal from "../../services/modalServices";
import Table from "./Table";
import Button from "./Button";
import { printFullDateTime } from "../../utils/time";

const LoginDevicesTable = ({ data, isDates = false, ...props }) => {
    let columns = [
        {
            name: "نوع الجهاز",
            reorder: true,
            selector: (row) => row.device_type,
        },
        {
            name: "اسم الجهاز",
            reorder: true,
            selector: (row) => row.device_name,
        },
        {
            name: "نظام التشغيل",
            reorder: true,
            selector: (row) => row.device_platform,
        },
        {
            name: "المتصفح",
            reorder: true,
            selector: (row) => row.device_browser,
        },
        {
            name: "عدد مرات تسجيل الدخول",
            reorder: true,
            selector: (row) => row.count,
        },
    ];
    if (isDates) {
        columns = [
            ...columns,
            {
                name: "آخر تسجيل دخول",
                reorder: true,
                selector: (row) => printFullDateTime(row.last_login_at),
            },
            {
                name: "آخر استخدام للمتصفح",
                reorder: true,
                selector: (row) => printFullDateTime(row.last_used_at),
            },
        ];
    }
    return (
        <>
            <Table columns={columns} data={data} pagination {...props} />
        </>
    );
};

export default LoginDevicesTable;

import React, { useEffect, useState } from "react";
import LogOutData from "../../../components/ui/LogOutData";
import LoginDataTable from "../../../components/ui/LoginDataTable";
import LogoutTable from "../../../components/ui/LogoutTable";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
import LoadingIcon from "../../../components/ui/LoadingIcon";
import CenterIcon from "../../../components/ui/CenterIcon";
import Button from "../../../components/ui/Button";
import LoginDevicesTable from "../../../components/ui/LoginDevicesTable";
import { printUnit } from "../../../utils/ar";
import modal from "../../../services/modalServices";
import { handleFormErrors, handleInputChange } from "../../../services/formServices";
import InputField from "../../../components/form/elements/InputField";
import InputIcon from "../../../components/form/elements/InputIcon";
import LogginDeviceLimitedAttemptTable from "../../../components/ui/LogginDeviceLimitedAttemptTable";

const LoggingTokensTable = ({ user }) => {
    const token = auth.getAdminToken();
    const config = auth.getAdminAuthConfig(token);

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    const [buttonIsLoading, setButtonIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        blockage_message: "",
    });
    const [errors, setErrors] = useState({});

    const unblockUser = async () => {
        try {
            modal.message({
                title: "هل انت متأكد؟",
                text: "هل انت متأكد من فك حظر هذا الطالب؟",
                icon: "warning",
                buttons: {
                    confirm: "تنفيذ الطلب!",
                    cancel: "إلغاء",
                },
                callback: async (e) => {
                    if (e && e !== "cancel") {
                        try {
                            setButtonIsLoading(true);
                            const { data: result } = await http.post(
                                `/api/users/${user.id}/unblock`,
                                {},
                                config
                            );
                            modal.message({
                                title: "عملية ناجحة",
                                text: "تم تنفيذ طلبك بنجاح",
                                icon: "success",
                            });
                            setData({ ...data, is_blocked: false });
                            setButtonIsLoading(false);
                        } catch (error) {
                            setButtonIsLoading(false);
                        }
                    }
                },
            });
        } catch (error) {}
    };
    const blockUser = async () => {
        try {
            modal.message({
                title: "هل انت متأكد؟",
                text: "هل انت متأكد من حظر هذا الطالب؟",
                icon: "warning",
                buttons: {
                    confirm: "تنفيذ الطلب!",
                    cancel: "إلغاء",
                },
                callback: async (e) => {
                    if (e && e !== "cancel") {
                        try {
                            setButtonIsLoading(true);
                            const { data: result } = await http.post(
                                `/api/users/${user.id}/block`,
                                formData,
                                config
                            );
                            modal.message({
                                title: "عملية ناجحة",
                                text: "تم تنفيذ طلبك بنجاح",
                                icon: "success",
                            });
                            setData({
                                ...data,
                                is_blocked: true,
                                blockage_message: formData.blockage_message,
                            });
                            setButtonIsLoading(false);
                        } catch ({ response }) {
                            handleFormErrors(response, setButtonIsLoading, setErrors, false);
                            setButtonIsLoading(false);
                        }
                    }
                },
            });
        } catch (error) {}
    };

    const increaseLimitedDevices = async () => {
        try {
            modal.message({
                title: "هل انت متأكد؟",
                text: "هل انت متأكد من زيادة عدد المتصفحات المسموحة لهذا الطالب؟",
                icon: "warning",
                buttons: {
                    confirm: "تنفيذ الطلب!",
                    cancel: "إلغاء",
                },
                callback: async (e) => {
                    if (e && e !== "cancel") {
                        try {
                            setButtonIsLoading(true);
                            const { data: result } = await http.post(
                                `/api/users/${user.id}/increase_limited_devices`,
                                {},
                                config
                            );
                            modal.message({
                                title: "عملية ناجحة",
                                text: "تم تنفيذ طلبك بنجاح",
                                icon: "success",
                            });
                            setData({
                                ...data,
                                able_to_login_from_new_device: true,
                                current_device_limit: data.current_device_limit + 1,
                            });
                            setButtonIsLoading(false);
                        } catch ({ response }) {
                            handleFormErrors(response, setButtonIsLoading, setErrors, false);
                            setButtonIsLoading(false);
                        }
                    }
                },
            });
        } catch (error) {}
    };

    const getLoggingData = async () => {
        try {
            const { data: result } = await http.get(`/api/users/${user.id}/tokens`, config);
            setData(result);
            setLoading(false);
        } catch (error) {}
    };

    useEffect(() => {
        getLoggingData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!loading ? (
                <div className="space-y-10 pt-10">
                    {data.is_blocked ? (
                        <div className="space-y-5">
                            <div className="bg-rose-500 w-full flex-center-both text-center p-4 space-x-4 space-x-reverse rounded-md font-w-bold font-h1">
                                <CenterIcon icon="bi:sign-do-not-enter-fill" />
                                <div>هذا الحساب محظور !</div>
                                <CenterIcon icon="bi:sign-do-not-enter-fill" />
                            </div>
                            <div className="">
                                سبب الحظر :{" "}
                                <span className="font-w-bold mr-2 p-2 rounded-md bg-yellow-500">
                                    {data.blockage_message}
                                </span>
                            </div>
                            <div className="flex-center-both">
                                <Button
                                    color="blue"
                                    isLoading={buttonIsLoading}
                                    onClick={unblockUser}
                                >
                                    فك حظر هذا الحساب
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="flex-center-both">
                                <InputField
                                    onChange={handleInputChange}
                                    data={formData}
                                    setData={setFormData}
                                    errors={errors}
                                    {...{
                                        id: "blockage_message",
                                        placeholder: "سبب الحظر !",
                                        icon: <InputIcon icon={"bi:sign-do-not-enter-fill"} />,
                                    }}
                                />
                                {/* <InputField data={}/> */}
                                <Button
                                    color="rose"
                                    className="font-w-bold font-h3"
                                    onClick={blockUser}
                                >
                                    {" "}
                                    حظر هذا المستخدم !
                                </Button>
                            </div>
                        </div>
                    )}

                    <div className="space-y-2">
                        <div className="flex-center-both text-center">
                            عدد المتصفحات المسموحة لهذا المستخدم :{" "}
                            <span className="px-2 rounded-full bg-blue-500">
                                {printUnit(data.current_device_limit, "جهاز")}
                            </span>
                        </div>
                        <div className="flex-center-both text-center">
                            {data.able_to_login_from_new_device ? (
                                <div className="font-w-bold">
                                    {"يمكن لهذا المستخدم تسجيل الدخول من متصفح جديد !"}
                                </div>
                            ) : (
                                <div className="space-y-2">
                                    <div className="font-w-bold text-rose-500">
                                        {
                                            "لا يمكن لهذا المستخدم تسجيل الدخول من متصفح جديد, يجب اضافة متصفح جديد للمستخدم اولًا"
                                        }
                                    </div>

                                    <div className="flex-center-both">
                                        <Button color="blue" onClick={increaseLimitedDevices}>
                                            اضافة متصفح آخر لهذا المستخدم !
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex-center-both text-center">
                        عدد المتصفحات اللي دخل منها هذا المستخدم :{" "}
                        <span className="px-2 rounded-full bg-rose-500">
                            {printUnit(data.devices_info.length, "جهاز")}
                        </span>
                    </div>
                    <LoginDevicesTable data={data.devices_info} isExcel={false} isDates={true} />

                    <div className="flex-center-both text-center">
                        عدد محاولات تسجيل الدخول الممنوعة آخر يوم:{" "}
                        <span className="px-2 rounded-full bg-rose-500">
                            {printUnit(data.logged_limited_devices_attempts.length, "مرة")}
                        </span>
                    </div>

                    <LogginDeviceLimitedAttemptTable
                        data={data.logged_limited_devices_attempts}
                        isAdmin={true}
                    />

                    <LogOutData
                        deleted_today={data.deleted_today}
                        deleted_this_week={data.deleted_this_week}
                    />
                    <LoginDataTable isAdmin={config} data={data.tokens} />
                    <LogoutTable data={data.deleted_tokens} />
                </div>
            ) : (
                <div className="font-h1 flex-center-both">
                    <LoadingIcon />
                </div>
            )}
        </>
    );
};

export default LoggingTokensTable;

import * as React from "react";
import khaledMasked from "./khaledMasked.png";
import LegendTypo from "./LegendTypo.png";
import { useEffect } from "react";

const SakrHero = ({ style, className }) => {
    useEffect(() => {
        const paths = document.querySelectorAll(".cls-1");
        console.log(paths);
        paths.forEach((path, index) => {
            const length = path.getTotalLength();
            const delay = index * 0.5;
            path.style.strokeDasharray = length;
            path.style.strokeDashoffset = length;
            path.style.animation = `drawPath-${index} 18s ${delay}s ease-in-out infinite`;

            const animation = `
                @keyframes drawPath-${index} {
                    0% {
                        stroke-dashoffset: ${length};
                        stroke: var(--initial-stroke-color, #d84d50);
                    }
                    50% {
                        stroke-dashoffset: 0;
                        stroke: var(--secondary-stroke-color, #63D497);
                    }
                    100% {
                        stroke-dashoffset: ${length};
                        stroke: var(--initial-stroke-color, #d84d50);
                    }
                }
            `;

            // Append the animation to the document
            const styleSheet = document.styleSheets[0];
            styleSheet.insertRule(animation, styleSheet.cssRules.length);
        });
    }, []);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 972 1063"
            style={style}
            className={className}
        >
            <defs>
                <style>
                    {`
                    .cls-1 {
                        fill: none;
                        stroke: var(--initial-stroke-color, #d84d50);
                        stroke-width: 2;
                    }

                    .cls-2 {
                        fill: #e11d3f;
                    }
                    `}
                </style>
            </defs>

            <g>
                <g id="Layer_1">
                    <path
                        className="cls-2"
                        d="M478.7,675.3c64.1,8.9,135.2-24.9,175.4-74.3,35.9-44.2,57.2-107.3,115.7-125.1,37.1-11.2,75.3-1.4,109.6,13.9,31.8,14.2,67.6,31.3,80.2,66.5,10,28,1,60.2-17,83.9-18,23.7-43.7,40.3-70,54.2-14.5,7.7-30.1,15.5-38.4,29.7-16.6,28.2,3.9,62.8,21.1,90.7,29.1,47.4,50.6,106.5,22.9,160.5-12.9,25.3-39,42.2-66.6,48.6-20.8,4.8-42.2,3.9-63,.1-21.1-3.8-27-12.2-43.9-24-35.3-24.6-81.1-38.1-123.6-41.8-26.8-2.3-56.1-.6-80.4,12.2-22.4,11.8-40.2,24.1-66.4,28.3-64.9,10.4-130.5-28.5-167.2-83-32.9-49-46.1-113-26.3-168.7,19.7-55.6,75.6-98.9,134.6-95.8,35.4,1.9,68.2,19,103.3,23.9h0Z"
                    />
                    <path
                        className="cls-1"
                        d="M422.9,832.8c-27.6,9.1-57.2,12.5-85.1,9.4-37.3-4.1-68.8-19.2-91.1-43.5-27.6-30.1-38.6-70.6-49.1-109.8-6.2-23.1-12.7-47-22.5-68.7l-2.8-6.1c-17-37.6-33.1-73.1-35.8-107.6-2.9-38.6,11.3-71.1,44.9-102.2,42.7-39.4,80.3-58.2,122-60.7,17.3-1,34.5,2.6,51,6.1,19.5,4.1,39.6,8.4,60,4.9,39.2-6.7,66.2-36.3,92.2-64.9,10.2-11.2,20.8-22.9,31.8-32.7,46.6-41.6,104.7-46.6,150.1-46.3,39.7.3,81.2,17.2,116.9,47.6,33.9,28.8,59.5,67.6,70.3,106.4,17.6,63.2.1,135.2-44.5,183.3-21.1,22.8-42.5,25.4-69.5,28.6-2.3.3-4.6.6-7,.9-66.8,8.4-116.5,66.8-156.5,113.6-46.8,54.8-94,106.4-157.2,134.4-6,2.6-12.1,5-18.3,7.1h0ZM265.6,353.8c-26.9,8.9-53.4,26-82.2,52.6-72,66.6-42.6,131.5-8.6,206.7l2.8,6.2c9.9,21.9,16.4,45.9,22.6,69.1,10.5,38.8,21.3,79,48.5,108.6,49.8,54.3,134.1,51.7,191.4,26.4,62.7-27.8,109.7-79.1,156.2-133.7,40.3-47.2,90.4-106,158.2-114.6,2.4-.3,4.7-.6,7-.9,27.5-3.3,47.4-5.7,67.8-27.8,44-47.4,61.2-118.4,43.9-180.8-23.2-83.3-106-151.5-184.6-152-44.9-.3-102.4,4.6-148.3,45.6-10.9,9.8-21.5,21.3-31.6,32.5-23,25.3-46.8,51.4-79.2,62.1-4.7,1.5-9.5,2.8-14.5,3.6-20.9,3.6-41.3-.8-61-5-16.4-3.5-33.3-7.1-50.3-6.1-13,.8-25.6,3.2-38.1,7.3h0Z"
                    />
                    <path
                        className="cls-1"
                        d="M417.2,815.6c-25.1,8.3-52,11.3-77.3,8.5-22.9-2.5-55.3-11.1-79.7-37.7-24.6-26.9-34.5-63.4-45-102.2-6.4-23.8-13.1-48.4-23.5-71.4l-2.8-6.2c-16.4-36.1-31.8-70.2-34.2-101.5-2.5-33.1,9.6-60,39.1-87.4,14.2-13.2,27.4-23.5,40.3-31.7,23.4-14.8,46.4-22.7,70.5-24.2,14.9-.9,30.1,2.3,46.1,5.7,21.2,4.5,43.1,9.1,66.9,5.1,15.7-2.7,30.5-8.5,45.1-17.7,22.3-14.1,40.2-33.8,57.5-52.9,10.4-11.4,20.1-22.1,30.5-31.4,6.1-5.4,12.6-10.3,19.3-14.6,30.1-19.1,66.6-27.4,118.6-27,35.4.2,72.8,15.6,105.2,43.2,31.2,26.5,54.7,62.1,64.5,97.5,16,57.3.1,122.5-40.3,166.1-4.3,4.6-8.6,8.2-13.1,11.1-12.4,7.9-26.2,9.5-45.3,11.8-2.3.3-4.6.6-7,.9-24.2,3-47.2,11.7-70.4,26.4-39,24.8-71.5,62.9-97.7,93.5-34.9,40.9-72.7,83.3-117.9,111.9-10.8,6.9-21.8,12.8-32.8,17.7-5.5,2.4-11,4.6-16.7,6.4h0ZM271.4,371.1c-12,4-23.8,9.7-35.8,17.3-12.7,8.1-25.8,18.3-39.9,31.4-28.9,26.7-40.7,53-38.3,85.2,2.3,30.8,17.7,64.7,33.9,100.5l2.8,6.2c10.5,23.2,17.2,47.9,23.6,71.8,10.4,38.4,20.2,74.7,44.3,101,23.8,25.9,55.5,34.3,78,36.8,30.6,3.4,63.4-1.9,92.6-14.8,10.9-4.8,21.8-10.7,32.5-17.5,44.8-28.4,82.5-70.6,117.3-111.3,26.2-30.8,58.9-69,98.3-94,23.6-14.9,47-23.7,71.5-26.8,2.4-.3,4.8-.6,7.1-.9,18.7-2.3,32.3-3.9,44.1-11.4,4.3-2.7,8.4-6.2,12.5-10.6,39.8-42.9,55.4-107.1,39.7-163.5-9.7-34.9-32.9-69.9-63.7-96.1-32-27.2-68.7-42.3-103.5-42.6-51.4-.3-87.5,7.9-117.1,26.6-6.6,4.2-13,9-19,14.3-10.2,9.1-20,19.8-30.3,31.2-17.5,19.2-35.5,39-58.1,53.4-14.9,9.4-30,15.4-46.1,18.1-24.3,4.2-46.5-.5-67.9-5.1-15.9-3.4-30.9-6.6-45.4-5.7-11.3.7-22.4,2.8-33.4,6.5h0Z"
                    />
                    <path
                        className="cls-1"
                        d="M411.4,798.3c-22.6,7.5-46.8,10.2-69.5,7.7-19.8-2.2-47.7-9.5-68.3-31.9-21.6-23.6-30.9-58.1-40.8-94.6-6.6-24.5-13.4-49.8-24.5-74.2l-2.8-6.2c-15.7-34.6-30.5-67.3-32.6-95.3-2.1-27.4,7.9-49.1,33.3-72.6,13.4-12.4,25.7-22.1,37.6-29.7,20.7-13.1,40.9-20.1,61.8-21.4,12.4-.7,26.4,2.2,41.2,5.4,21.8,4.6,46.5,9.9,73.7,5.2,18.2-3.1,35.1-9.7,51.7-20.3,24.3-15.4,43.1-36.1,61.3-56,10-11,19.5-21.5,29.1-30,5.3-4.7,11-9.1,16.9-12.8,26.9-17.1,60.5-24.5,108.7-24.2,31.1.2,64.4,14,93.5,38.9,28.5,24.2,49.9,56.5,58.8,88.5,14.3,51.3.1,109.7-36.1,148.8-3.2,3.4-6.3,6.1-9.5,8.1-8.9,5.6-20.4,7-37.7,9.1-2.3.3-4.7.6-7.1.9-26.9,3.4-52.4,12.9-77.9,29.1-41.4,26.2-74.8,65.5-101.8,97-34,39.9-70.8,81.1-113.8,108.3-10,6.4-20.3,11.9-30.5,16.4-4.9,2.2-10,4.1-15.1,5.8h0ZM277.1,388.4c-10.6,3.5-21.1,8.6-31.7,15.4-11.8,7.5-24,17.1-37.2,29.4-24.8,22.9-34.5,44-32.5,70.4,2.1,27.6,16.8,60,32.4,94.4l2.8,6.2c11.1,24.6,18,50,24.6,74.6,9.8,36.2,19,70.4,40.2,93.5,20,21.8,47.2,28.9,66.5,31,27.4,3,57-1.7,83.2-13.3,10-4.4,20.2-9.9,30.1-16.2,42.7-27.1,79.3-68.1,113.2-107.8,27-31.7,60.7-71.1,102.4-97.6,25.9-16.4,51.7-26.1,79-29.5,2.4-.3,4.8-.6,7.1-.9,17-2.1,28.2-3.4,36.6-8.7,3-1.9,5.9-4.4,8.9-7.7,35.6-38.4,49.5-95.8,35.5-146.2-8.8-31.5-29.9-63.2-57.9-87.1-28.7-24.5-61.3-38-91.8-38.2-47.7-.3-80.8,7-107.2,23.8-5.8,3.7-11.4,7.9-16.6,12.5-9.5,8.5-18.9,18.8-28.9,29.8-18.3,20.1-37.2,40.9-61.8,56.5-17,10.8-34.2,17.5-52.7,20.7-27.8,4.7-52.7-.6-74.7-5.2-14.7-3.1-28.5-6-40.5-5.3-9.7.6-19.2,2.4-28.7,5.6h0Z"
                    />
                    <path
                        className="cls-1"
                        d="M405.6,781c-20.1,6.7-41.6,9.1-61.7,6.9-16.7-1.8-40.1-7.9-56.8-26.1-18.6-20.3-27.4-52.7-36.6-87-6.8-25.2-13.8-51.2-25.4-77l-2.8-6.2c-15-33.1-29.2-64.4-31-89.2-1.4-18.2,2.8-35,27.5-57.9,12.6-11.6,24-20.7,35-27.6,18-11.4,35.4-17.5,53.1-18.6,9.9-.6,22.8,2.1,36.4,5,22.2,4.7,49.9,10.6,80.6,5.4,20.6-3.5,39.7-11,58.4-22.9,26.4-16.7,46-38.3,65-59.1,9.7-10.7,18.9-20.8,27.8-28.7,4.6-4.1,9.5-7.8,14.6-11,23.8-15.1,54.3-21.7,98.8-21.4,26.8.2,55.9,12.4,81.9,34.5,25.8,21.9,45.1,50.9,53.1,79.5,12.6,45.4,0,97-31.9,131.5-2.1,2.3-4,3.9-5.9,5.1-5.4,3.4-15.8,4.7-30.1,6.4h-.6c-2.2.3-4.4.6-6.6.9-29.6,3.7-57.5,14.1-85.4,31.8-43.7,27.7-78.2,68.1-105.9,100.6-33.1,38.8-68.8,78.8-109.7,104.8-9.3,5.9-18.7,11-28.1,15.1-4.4,2-8.9,3.7-13.5,5.2h0ZM255.2,419.2c-10.8,6.9-22.2,15.8-34.6,27.3-24,22.2-28,38.3-26.7,55.7,1.9,24.4,15.9,55.4,30.8,88.3l2.8,6.2c11.7,25.9,18.8,52.1,25.6,77.4,9.2,34,17.8,66.1,36,85.9,16.2,17.6,38.9,23.4,55.1,25.2,24.3,2.7,50.5-1.5,73.8-11.9,9.2-4.1,18.5-9.1,27.7-14.9,40.6-25.7,76.1-65.6,109.1-104.2,27.8-32.6,62.5-73.2,106.5-101.1,28.2-17.9,56.5-28.4,86.5-32.2,2.2-.3,4.4-.5,6.6-.8h.6c14-1.8,24.1-3,29-6.1,1.6-1,3.4-2.6,5.3-4.7,31.4-33.8,43.7-84.4,31.3-128.9-7.8-28.1-26.8-56.5-52.2-78.1-25.4-21.7-53.9-33.7-80.1-33.9-44-.3-74,6.2-97.3,21-5,3.1-9.7,6.8-14.2,10.7-8.8,7.9-17.9,17.9-27.6,28.5-19.1,21-38.8,42.7-65.5,59.6-19.1,12.1-38.5,19.7-59.4,23.3-31.2,5.3-59.1-.6-81.6-5.4-13.4-2.8-26.1-5.5-35.6-5-17.2,1-34.2,7-51.8,18.1h0Z"
                    />
                    <path
                        className="cls-1"
                        d="M399.8,763.7c-17.6,5.8-36.4,8-53.9,6-13.6-1.5-32.5-6.2-45.4-20.3-15.6-17-23.8-47.3-32.5-79.4-7-25.9-14.2-52.7-26.4-79.7l-2.8-6.2c-14.3-31.6-27.8-61.4-29.5-83.1-.9-11.3.1-23.2,21.7-43.1,11.8-10.9,22.4-19.3,32.4-25.6,15.3-9.7,29.9-14.9,44.5-15.8,7.5-.4,19.7,2.1,31.5,4.6,22.5,4.8,53.3,11.3,87.4,5.5,23-3.9,44.3-12.3,65.1-25.5,28.5-18.1,48.9-40.5,68.7-62.2,9.4-10.3,18.2-20.1,26.5-27.4,3.8-3.4,8-6.5,12.2-9.2,20.7-13.1,48.1-18.8,88.9-18.6,22.6.2,47.5,10.9,70.2,30.2,23.1,19.6,40.3,45.3,47.3,70.5,11,39.4,0,84.2-27.7,114.2-1,1.1-1.7,1.7-2,1.9l-.3.2c-3.3,1.4-13.5,2.6-22.5,3.7h-.5c-2.3.3-4.5.6-6.8.9-32.3,4.1-62.7,15.4-92.9,34.5-46,29.2-81.5,70.8-110,104.2-32.2,37.7-66.9,76.6-105.6,101.2-8.5,5.4-17.2,10.1-25.7,13.8-3.9,1.7-7.9,3.3-11.9,4.6h0ZM264.2,433.4l.7,1.2c-9.9,6.3-20.4,14.6-32,25.3-20.7,19.1-21.7,30.3-20.9,40.9,1.6,21.2,15,50.8,29.2,82.2l2.8,6.2c12.3,27.3,19.6,54.1,26.6,80.1,8.6,31.8,16.7,61.8,31.8,78.3,12.3,13.4,30.6,18,43.7,19.4,21.1,2.3,44-1.4,64.5-10.4,8.4-3.7,16.9-8.3,25.3-13.6,38.4-24.4,72.9-63.1,105-100.7,28.6-33.6,64.2-75.3,110.6-104.7,30.5-19.3,61.2-30.7,94-34.9,2.3-.3,4.6-.6,6.8-.8h.5c7.8-1,18.5-2.3,21.6-3.5.3-.2.8-.7,1.6-1.5,27.2-29.3,37.8-73.1,27.1-111.6-6.9-24.6-23.8-49.8-46.4-69.1-22.2-18.9-46.5-29.4-68.4-29.5-40.3-.3-67.2,5.3-87.5,18.1-4.1,2.6-8.1,5.6-11.8,9-8.1,7.2-16.9,16.9-26.2,27.2-19.9,21.9-40.5,44.5-69.3,62.7-21.1,13.4-42.8,21.9-66.1,25.8-34.6,5.9-65.7-.7-88.5-5.5-11.6-2.5-23.7-5-30.8-4.6-14.1.8-28.2,5.9-43.1,15.3l-.7-1.2h0Z"
                    />
                    <path
                        className="cls-1"
                        d="M394,746.4c-15.1,5-31.2,6.8-46.1,5.2-10.5-1.2-24.9-4.6-34-14.5-12.6-13.7-20.2-42-28.3-71.9-7.2-26.6-14.6-54.1-27.4-82.5l-2.8-6.2c-13-28.8-26.5-58.5-27.9-76.9-.5-6.2-1-12.7,15.9-28.3,11-10.1,20.7-17.9,29.8-23.6,12.7-8,24.4-12.3,35.8-13,5-.3,17.5,2.4,26.6,4.3,23.9,5.1,56.7,12.1,94.3,5.6,25.4-4.3,48.9-13.5,71.8-28,30.5-19.4,51.8-42.8,72.4-65.4,9.1-10,17.6-19.3,25.1-26,3.1-2.8,6.4-5.3,9.8-7.4,17.5-11.1,41.9-16,79.1-15.7,18.3.1,39,9.3,58.5,25.8,20.4,17.3,35.5,39.8,41.6,61.5,9,32.2.5,69.3-21.5,94.6l-.3.4h-.5c-4.3.8-11.2,1.6-14.9,2.1h-.2c-2.4.3-4.8.6-7.2.9-35,4.4-67.9,16.6-100.3,37.2-48.3,30.6-84.8,73.4-114.1,107.7-31.3,36.7-64.9,74.4-101.5,97.6-7.8,4.9-15.6,9.2-23.3,12.6-3.4,1.5-6.8,2.8-10.3,4h0ZM294.3,440.4c-6.3,2.1-12.8,5.3-19.6,9.6-9,5.7-18.6,13.3-29.4,23.3-15.9,14.7-15.5,20.5-15.1,26.1,1.4,18,14.7,47.5,27.7,76l2.8,6.2c12.9,28.6,20.4,56.2,27.6,82.9,8,29.6,15.5,57.5,27.7,70.8,8.5,9.3,22.2,12.5,32.2,13.6,18,2,37.5-1.2,55.1-9,7.6-3.4,15.3-7.5,23-12.4,36.3-23,69.7-60.6,100.9-97.1,29.4-34.5,66-77.4,114.7-108.3,32.8-20.8,66-33.1,101.5-37.6,2.4-.3,4.8-.6,7.2-.9h.2c3.5-.5,10-1.2,14.3-1.9,21.2-24.6,29.2-60.6,20.6-91.7-5.9-21.2-20.8-43.2-40.7-60.2-18.9-16.1-39.1-25.1-56.7-25.2-36.6-.2-60.5,4.5-77.6,15.3-3.3,2.1-6.5,4.5-9.5,7.2-7.4,6.6-15.9,15.9-24.9,25.8-20.7,22.8-42.1,46.3-73,65.9-23.2,14.7-47,24-72.8,28.4-38.1,6.5-71.2-.5-95.3-5.6-9-1.9-21.2-4.5-25.9-4.2-4.9.3-9.8,1.3-14.8,2.9h0Z"
                    />
                    <path
                        className="cls-1"
                        d="M388.2,729.2c-12.6,4.2-26,5.7-38.4,4.4-10.3-1.1-18.5-4.3-22.5-8.7-9.6-10.5-17-37.8-24.1-64.3-7.4-27.3-15-55.5-28.4-85.2l-2.8-6.2c-11.8-26.1-25.2-55.6-26.3-70.8h0c0-.6,0-1.1,0-1.4v-.3s.1-.3.1-.3c.6-1.2,2.6-4.6,10.1-11.5,10.1-9.4,19-16.4,27.2-21.6,9.9-6.3,18.7-9.6,26.9-10.1h.1c3.3,0,12.9,2,21.8,3.9,25.4,5.4,60.1,12.8,101.2,5.8,27.8-4.8,53.5-14.8,78.5-30.6,32.6-20.7,54.7-45,76.2-68.5,8.7-9.6,17-18.7,23.8-24.7,2.4-2.1,4.9-4,7.4-5.6,14.4-9.1,35.7-13.1,69.2-12.9,13.8,0,30.8,7.9,46.8,21.5,17.7,15,30.7,34.2,35.8,52.5,6.7,24.2,1.5,52-13.6,72.6l-.3.5h-.6c-2.7.4-5.5.8-7.3,1h-.2c-2.4.3-4.8.6-7.3.9-37.8,4.8-73,17.8-107.8,39.9-50.7,32.1-88.1,76-118.2,111.3-30.4,35.6-62.9,72.2-97.4,94.1-7,4.4-14,8.2-20.9,11.3-2.9,1.3-5.8,2.4-8.8,3.4h0ZM248.3,497.1c0,.3,0,.6,0,.8h0c1.1,14.9,14.4,44.2,26.1,70l2.8,6.2c13.5,29.9,21.2,58.3,28.6,85.6,7.1,26.2,14.4,53.2,23.5,63.2,3.6,3.9,11.2,6.8,20.8,7.8,14.8,1.6,31-1,45.7-7.5,6.8-3,13.7-6.7,20.6-11.1,34.1-21.6,66.5-58.1,96.7-93.5,30.2-35.4,67.8-79.5,118.8-111.8,35.1-22.3,70.8-35.4,108.9-40.3,2.5-.3,4.9-.6,7.3-.9h.2c1.7-.2,4.2-.5,6.7-.9,14.3-19.9,19.2-46.6,12.8-69.8-5-17.8-17.7-36.5-35-51.2-15.4-13.2-31.8-20.7-45-20.8-32.9-.2-53.7,3.6-67.7,12.5-2.4,1.5-4.8,3.4-7.1,5.4-6.7,6-14.9,15-23.5,24.5-21.5,23.7-43.8,48.1-76.7,69-25.3,16-51.3,26.2-79.5,31-41.5,7.1-76.6-.3-102.2-5.8-4.8-1-17.7-3.8-21.3-3.9-7.7.5-16.1,3.7-25.6,9.7-8,5.1-16.8,12-26.8,21.3-6.7,6.2-8.8,9.4-9.3,10.4h0Z"
                    />
                    <path
                        className="cls-1"
                        d="M382.4,711.9c-10.1,3.4-20.8,4.6-30.6,3.5-5.7-.6-9.5-2-11.1-3h-.2c0-.1-.1-.3-.1-.3-6.5-7.7-14.4-37-19.7-56.4-7.6-28-15.4-56.9-29.4-88l-2.8-6.2c-8.6-19.1-20.5-45.2-23.8-59.3l-.2-.7.5-.5c.9-.9,1.9-1.9,3.1-2.9,9.3-8.6,17.4-15,24.6-19.6,6.6-4.2,12.4-6.6,17.1-7.2h.3c1.9.2,6.5,1,17.8,3.4,26.8,5.7,63.6,13.5,108,5.9,30.3-5.2,58.1-16,85.2-33.2,34.7-22,57.7-47.2,79.9-71.6,8.4-9.2,16.3-17.9,22.4-23.4,1.6-1.5,3.3-2.7,5.1-3.9,11.1-7.1,30-10.3,59.3-10.1,7.3,0,20.3,4.6,35.1,17.1,14.7,12.6,26,28.8,30.1,43.5,4.5,16.3,2.1,35.2-6.6,50.6l-.3.6h-.7c-2.1.3-4.3.6-6.4.9-40.5,5.1-78.2,19-115.3,42.5-53,33.6-91.4,78.7-122.3,114.9-29.5,34.6-61,70-93.3,90.5-6.2,4-12.5,7.3-18.6,10-2.4,1-4.7,2-7.2,2.8h0ZM342.4,710.2c1.1.6,4.4,1.9,9.7,2.5,11.7,1.3,24.6-.9,36.3-6.1,5.9-2.6,12.1-5.9,18.2-9.8,32-20.3,63.3-55.6,92.6-90,31-36.4,69.6-81.6,122.9-115.4,37.5-23.7,75.5-37.8,116.4-42.9,1.9-.2,3.8-.5,5.7-.7,8-14.6,10.3-32.5,6-47.9-4-14.2-14.9-30-29.2-42.2-14.2-12.1-26.5-16.4-33.3-16.5-28.8-.2-47.1,2.9-57.8,9.6-1.6,1-3.2,2.2-4.7,3.6-6,5.3-13.9,14-22.2,23.2-22.3,24.5-45.4,49.9-80.4,72.1-27.4,17.3-55.6,28.3-86.2,33.6-45,7.7-82-.2-109-5.9-10.8-2.3-15.5-3.1-17.4-3.4-4.3.6-9.7,2.9-15.9,6.8-7.1,4.5-15,10.8-24.2,19.3-.9.8-1.7,1.6-2.4,2.3,3.6,14,15,39.4,23.5,58l2.8,6.2c14.1,31.3,22,60.3,29.5,88.4,5.1,19,12.9,47.7,19,55.2h0Z"
                    />
                    <path
                        className="cls-1"
                        d="M376.6,694.6c-7.6,2.5-15.6,3.5-22.8,2.7h-1.2c0-.1-.3-.9-.3-.9-4.6-10.7-11.2-35-14-45.4-7.7-28.7-15.7-58.3-30.4-90.7l-2.8-6.2c-6.2-13.7-14.7-32.5-19.6-46l-.3-.9.7-.6c6.2-5.4,11.6-9.6,16.5-12.7,3.7-2.3,6.2-3.5,7.7-4h.4c0-.1.4,0,.4,0,3.1.6,7.6,1.5,13.4,2.7,28.3,6,67,14.2,114.9,6,32.7-5.6,62.8-17.3,91.9-35.7,36.8-23.3,60.6-49.5,83.6-74.8,8.1-8.9,15.7-17.2,21.1-22,.9-.8,1.8-1.5,2.7-2.1,5.4-3.4,18.3-7.4,49.4-7.2,2.4,0,11.3,2.5,23.4,12.8,11.9,10.1,21.2,23.4,24.3,34.5,2.5,9,2.1,19.2-1.2,28.6l-.3.8h-.8c-41.9,5.9-80.8,20.6-119.2,44.9-55.3,35.1-94.7,81.3-126.4,118.4-28.6,33.5-59,67.8-89.1,86.9-5.5,3.5-10.9,6.4-16.2,8.7-1.8.8-3.7,1.5-5.6,2.2h0ZM354.6,694.6c8.4.8,17.8-.8,26.5-4.7,5.1-2.3,10.4-5.1,15.8-8.6,29.8-18.9,60.1-53,88.5-86.4,31.8-37.3,71.4-83.7,127-119,38.4-24.4,77.5-39.1,119.4-45,2.9-8.7,3.2-18,.9-26.2-3-10.7-12-23.4-23.5-33.2-11.6-9.9-20-12.1-21.7-12.1-30.4-.2-42.8,3.6-47.9,6.8-.8.5-1.6,1.1-2.3,1.8-5.3,4.7-12.8,13-20.9,21.8-23.1,25.4-47.1,51.7-84.1,75.2-29.4,18.7-59.8,30.5-92.9,36.1-48.4,8.3-87.4,0-115.9-6.1-6.7-1.4-10.7-2.2-12.9-2.6-1.4.5-3.6,1.6-6.8,3.6-4.6,2.9-9.7,6.8-15.5,11.8,4.9,13.4,13.1,31.6,19.2,44.9l2.8,6.2c14.7,32.6,22.8,62.4,30.5,91.1,2.7,10.1,9,33.5,13.5,44.3h0Z"
                    />
                    <path
                        className="cls-1"
                        d="M370.9,677.3c-1.4.5-2.9.9-4.4,1.2l-1.2.3-.4-1.2c-3.6-11.2-7.4-25.3-9-31.3-7.9-29.4-16.1-59.7-31.4-93.5l-2.8-6.2c-4.4-9.7-9.9-21.8-14.4-32.7l-.4-1,.9-.6c1.5-1.1,3-2.1,4.3-3l.8-.5h.5c1.9.5,4.1,1,6.9,1.5,29.7,6.3,70.4,15,121.7,6.2,35.1-6,67.4-18.5,98.6-38.3,38.8-24.6,63.5-51.7,87.3-77.9,7.4-8.1,15.1-16.5,19.7-20.7.2-.2.3-.2.3-.2h.1c.8-.5,8.5-4.5,37.6-4.4h.2s.2,0,.2,0c1.7.6,6.4,2.7,13,8.4,10.4,8.9,16.8,19.2,18.6,25.6.6,2.3.9,4.7.9,7.3v1.1s-1.1.2-1.1.2c-39.7,7.2-76.8,22.1-113.5,45.4-57.6,36.5-98,83.9-130.5,122-27.7,32.4-57,65.6-85,83.4-4.7,3-9.4,5.5-13.8,7.5-1.3.6-2.6,1.1-3.9,1.5h0ZM367.2,675.5c2.2-.6,4.4-1.3,6.5-2.2,4.3-1.9,8.8-4.4,13.4-7.3,27.7-17.6,56.9-50.5,84.4-82.8,32.6-38.2,73.2-85.8,131.1-122.5,36.7-23.3,73.8-38.2,113.4-45.5,0-1.9-.3-3.8-.8-5.4-1.6-5.9-7.8-15.7-17.7-24.2-6-5.1-10.2-7.1-12-7.8-27.2,0-35.1,3.5-35.9,3.9,0,0,0,0,0,0-4.6,4.1-12.2,12.4-19.5,20.5-24,26.3-48.7,53.5-87.9,78.4-31.5,20-64.1,32.6-99.6,38.7-51.9,8.9-92.8.2-122.8-6.2-2.5-.5-4.6-1-6.4-1.3-1,.6-2,1.4-3.1,2.1,4.4,10.5,9.6,22.2,13.9,31.6l2.8,6.3c15.3,34,23.6,64.4,31.5,93.9,1.6,5.8,5.1,19,8.6,30h0Z"
                    />
                    <path
                        className="cls-1"
                        d="M377.1,654l-.5-1.8c-1.2-4.4-2.3-8.3-3-10.8-8.1-30.1-16.5-61.2-32.4-96.2,0,0-4.2-9.3-4.9-10.9l-1.1-2.4,2.6.5c26.5,5,62.7,9.7,107.5,2,21.6-3.7,41.8-9.6,61.6-17.9l6.2-2.6-4.7,4.8c-18.3,18.8-34.4,37.7-48.6,54.4-26.8,31.4-55.1,63.4-80.9,79.8l-1.8,1.1ZM339.9,535.6c1.5,3.3,3.9,8.5,3.9,8.5,16,35.3,24.4,66.5,32.5,96.7.6,2.1,1.4,5.3,2.4,9,25.2-16.4,52.8-47.7,79-78.4,13.1-15.3,27.7-32.5,44.2-49.9-18.1,7.2-36.6,12.3-56.2,15.7-43.7,7.5-79.3,3.3-105.8-1.5h0ZM626.1,429.4l4.8-5.2c3.5-3.8,7-7.6,10.4-11.4l.2-.3c5.5-6,11.7-12.9,15.9-17.1l.3-.3h.4c3-.7,10-1.9,23.4-2.1h.4s.3.2.3.2c1.6,1,3.4,2.3,5.2,3.9,1.9,1.6,3.6,3.3,5.2,5.1l1.5,1.7-2.2.6c-20.2,5.2-40.3,12.5-59.6,21.8l-6.4,3.1h0ZM659.1,397.7c-4.1,4.1-10.2,10.8-15.5,16.6l-.2.3c-1.9,2.1-3.8,4.2-5.8,6.3,16.8-7.6,34.1-13.8,51.6-18.5-1.1-1.1-2.2-2.1-3.4-3.2-1.6-1.3-3.1-2.5-4.5-3.4-12.4.2-19,1.2-22.1,1.8h0Z"
                    />
                    <path
                        className="cls-1"
                        d="M387.6,623.4l-.6-2.1c-5.4-19.6-11.7-40.9-21-64.3l-.8-2.1,2.2.2c27.5,2.7,54,1.8,80.9-2.8.8-.1,1.5-.3,2.3-.4l3.8-.7-2.5,2.9c-1.8,2.1-3.6,4.2-5.3,6.2l-.7.8c-17.6,20.6-37.9,44-56.7,60.7l-1.6,1.5ZM369.4,558.1c8.6,21.8,14.5,41.9,19.6,60.3,18.2-16.5,37.8-39,54.8-59l.7-.8c.9-1.1,1.9-2.2,2.8-3.3-25.9,4.3-51.5,5.2-78,2.8Z"
                    />
                    <path
                        className="cls-1"
                        d="M396.7,589.3l-.7-2.2c-1.1-3.5-2.2-6.9-3.3-10.3l-.6-1.8h1.9c4.3,0,8.7,0,12.9,0h3.2c0,0-2.1,2.3-2.1,2.3-3.3,3.7-6.6,7.2-9.7,10.5l-1.6,1.7h0ZM395.8,577.7c.7,2.1,1.4,4.2,2,6.3,1.9-2,3.9-4.2,5.9-6.4-2.6,0-5.3,0-7.9,0Z"
                    />
                    <image width={972} height={1063} xlinkHref={LegendTypo} />
                    <image
                        width={678}
                        height={1063}
                        transform="translate(244.2 -3.4)"
                        xlinkHref={khaledMasked}
                    />
                </g>
            </g>
        </svg>
    );
};
export default SakrHero;

import React, { useContext, useEffect } from "react";

// import profile from "../../assets/Einstein.png";
import Container from "../../components/ui/Container";
// import FlexRowReverse from "../../components/ui/FlexRowReverse";

import icon1 from "../../assets/time.svg";
import icon2 from "../../assets/repeat.svg";
import icon3 from "../../assets/book.svg";
import starsbg from "../../assets/Group8.png";
import ScrollingContext from "../../context/ScrollingContext";
import { getPartialPercentage, getScrollingPercentage } from "../../services/scrollingServices";
import AboutImg from "../../assets/khaled-imagery/AboutCoolWaterMelon.png";
import AboutCardIcon from "../../assets/khaled-imagery/wideCardIcon.png";

import Pattern from "../../assets/khaled-imagery/WaterMelonPattern.png";
const AboutSection = () => {
    // const { scrolling } = useContext(ScrollingContext);

    // const animateMidButtons = () => {
    //     const container = document.querySelector(".mid-section");
    //     // const windowHeight = window.innerHeight;

    //     const height = container.getBoundingClientRect().height;

    //     const offsetTop = container.getBoundingClientRect().y + scrolling;

    //     const translate = -20;

    //     // const scroll = scrolling + windowHeight;

    //     const movable = [
    //         { degree: 30, button: document.querySelector(".mid__card:nth-child(3) .mid__text") },
    //         { degree: -20, button: document.querySelector(".mid__card:nth-child(2) .mid__text") },
    //         { degree: 20, button: document.querySelector(".mid__card:nth-child(1) .mid__text") },
    //     ];

    //     const { percentage } = getScrollingPercentage(scrolling, offsetTop, height, 0, height / 2);

    //     // if(window.width <  768){

    //     // }

    //     movable.forEach((button, index) => {
    //         let elementPercentage =
    //             1 -
    //             getPartialPercentage(percentage, (2 - index) * 0.1 + 0, 0.8 + (2 - index) * 0.01);
    //         if (window.width < 768) {
    //             elementPercentage =
    //                 1 -
    //                 getPartialPercentage(
    //                     percentage,
    //                     (2 - index) * 0.3 + 0,
    //                     0.3 + (2 - index) * 0.03
    //                 );
    //         }
    //         moveButton(
    //             button.button,
    //             translate * elementPercentage,
    //             button.degree * elementPercentage
    //         );
    //         toggleButtonClasse(button.button, 1 - elementPercentage);
    //     });
    // };

    // function moveButton(button, rem, deg) {
    //     button.style.transform = `translatey(${rem}rem) rotate(${deg}deg)`;
    // }
    // function toggleButtonClasse(button, percentage) {
    //     percentage < 1 ? button.classList.add("stable") : button.classList.remove("stable");
    // }
    // useEffect(() => {
    //     animateMidButtons();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [scrolling]);

    let AboutCards = [
        {
            msg: "وفر وقتك وروق",
            value: "مزاجك",
        },
        { msg: "أتفرج كتير زي ما", value: " تحب" },
        { msg: "إمتحانات بشكل ", value: "مستمر" },
    ];
    return (
        <>
            <section className="mid-section py-5 bg-cyan-800 relative shadow-large">
                <div
                    className="inset-0 w-full h-full z-0 absolute opacity-100 smooth"
                    style={{
                        backgroundImage: "url(" + Pattern + ")",
                        backgroundSize: "cover",
                        backgroundPosition: "center top",
                        backgroundRepeat: "repeat",
                    }}
                ></div>

                <Container className="py-5 flex-center-both md:flex-row flex-col-reverse relative z-10">
                    <div className="basis-full md:basis-1/2 flex justify-center">
                        <div className="flex flex-col relative gap-14 mx-5  w-full md:w-3/4">
                            <div className="flex justify-between absolute -z-10 right-0 top-6 bottom-0 w-full">
                                <div className="w-[40px] bg-star  max-h-full lg:max-h-[400px]  bg-sakrBlue-950 dark:bg-sakrBlue-700 rounded-tr-xl rounded-br-xl smooth " />
                                <div className="w-[40px] bg-star max-h-full lg:max-h-[400px] bg-sakrBlue-950 dark:bg-sakrBlue-700 smooth " />
                            </div>
                            {AboutCards.map((card, index) => (
                                <div className="bg-sakrBlue-200 relative -translate-x-5 hover:translate-x-0 flex-center-both flex-col space-y-2 md:space-y-0 md:flex-row  px-10 py-5 rounded-md outline outline-offset-[7px] outline-sakrBlue-400 dark:bg-sakrBlue-950 smooth space-x-reverse space-x-5">
                                    <div
                                        className="inset-0 w-full h-full z-0 absolute opacity-40 smooth"
                                        style={{
                                            backgroundImage: "url(" + starsbg + ")",
                                            backgroundRepeat: "repeat",
                                        }}
                                    ></div>
                                    <img
                                        src={AboutCardIcon}
                                        alt="waterMelonCardIcon"
                                        className="z-10 w-14 md:w-18"
                                    />
                                    <h1 className="font-rady z-10 text-center md:text-right font-w-bold text-3xl md:text-4xl clr-text-primary smooth">
                                        {card.msg}{" "}
                                        <span className="text-red-500">{card.value}</span>
                                    </h1>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="basis-full md:basis-1/2  flex-center-both">
                        <img src={AboutImg} alt="waterMelonCool" />
                    </div>
                </Container>
            </section>
        </>
    );
};

export default AboutSection;

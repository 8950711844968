import { createContext, useEffect, useState } from "react";
import auth from "../services/authServices";
import { enableAutoLogin, isAnalytics } from "../services/defaultSettings";
import http from "../services/httpServices";
import { toast } from "react-toastify";
import a from "../services/analyticsServices";
import { v4 as uuid } from "uuid";
import localStorageService from "../services/localStorageService";
import { isObjectEmpty } from "../utils/objects";
import modal from "../services/modalServices";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState("");
    const [user, setUser] = useState({});
    const [isNavigated, setIsNavigated] = useState(false);

    const [subscribedCourses, setSubscribedCourses] = useState(false);
    const [isSubscribedCoursesFetchingLoading, setIsSubscribedCoursesLoading] = useState(true);
    const [isSubscribedCoursesFetchingError, setIsSubscribedCoursesFetchingError] = useState(false);

    const getSubscribedCourses = async () => {
        const CACHE_DURATION = 2 * 60 * 60 * 1000;

        let authUser = auth.getUser() || {};
        let SUBSCRIBED_COURSES_KEY;

        if (!isObjectEmpty(authUser) && authUser?.email) {
            SUBSCRIBED_COURSES_KEY = `subscribedCourses_${authUser?.email}`;
        }
        const cachedData = localStorageService.get(SUBSCRIBED_COURSES_KEY);
        const currentTime = Date.now();
        if (cachedData !== null) {
            const { courses, timestamp } = cachedData;
            setSubscribedCourses(courses);
            setIsSubscribedCoursesLoading(false);
            return;
        }
        setIsSubscribedCoursesLoading(true);
        try {
            const token = auth.getToken();
            const config = auth.getAuthConfig(token);
            const { data } = await http.get("/api/sellables/subscribed", config);

            setSubscribedCourses(data);
            if (!isObjectEmpty(authUser) && authUser?.email) {
                localStorageService.set(
                    SUBSCRIBED_COURSES_KEY,
                    { courses: data, timestamp: currentTime },
                    CACHE_DURATION
                );
            }
            setIsSubscribedCoursesLoading(false);
        } catch (error) {
            setIsSubscribedCoursesFetchingError(true);
            setIsSubscribedCoursesLoading(false);
        }
    };
    const handleSubscribedCourses = () => {
        let SUBSCRIBED_COURSES_KEY;
        let authUser = auth.getUser() || {};
        if (!isObjectEmpty(authUser) && authUser?.email) {
            SUBSCRIBED_COURSES_KEY = `subscribedCourses_${authUser?.email}`;
        }
        const DELAY = 1000 * 60 * 5;
        const cachedData = localStorageService.get(SUBSCRIBED_COURSES_KEY);
        const currentTime = Date.now();
        if (cachedData !== null) {
            const { timestamp } = cachedData;
            if (currentTime - timestamp < DELAY) {
                modal.message({
                    title: "⏱️ مهلة بسيطة!",
                    text: "اشتراكاتك موجودة لكن لازم نستنى خمساية قبل ما تعمل ريفريش تاني! 🛑",
                    icon: "warning",
                });
                return;
            }
        }
        localStorageService.remove(SUBSCRIBED_COURSES_KEY);
        getSubscribedCourses();
    };
    const [isFreeTrial, setIsFreeTrial] = useState(false);
    const [isFreeTrialDone, setIsFreeTrialDone] = useState(false);
    const [freeTrialTimeLeft, setFreeTrialTimeLeft] = useState(0);
    const [isFreeTrialMenuClosed, setIsFreeTrialMenuClosed] = useState(false);

    const [isCheckedInvalidName, setIsCheckedInvalidName] = useState(false);
    const [isValidName, setIsValidName] = useState(false);

    const [isPrepaidChecked, setIsPrepaidChecked] = useState(false);

    const [admin, setAdmin] = useState({});

    const [prepaidCourses, setPrepaidCourses] = useState(0);

    const [adminToken, setAdminToken] = useState("");

    const [loginAttemp, setLoginAttemp] = useState(false);

    const [uid, setUid] = useState(null);
    const [visitorId, setVisitorId] = useState(0);
    const [visitorVisitId, setVisitorVisitId] = useState(0);

    const getAndStoreUID = () => {
        const generatedUid = uuid();
        a.setUid(generatedUid);
        setUid(generatedUid);
    };
    const getAndStoreVistorId = async () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        let params = Object.fromEntries(urlSearchParams.entries());

        const visitorData = {};
        visitorData.uid = uid;
        visitorData.fbclid = params.fbclid;
        visitorData.referrer = document.referrer;
        visitorData.full_url = window.location.href;
        visitorData.is_admin = auth.getAdminToken() ? 1 : 0;

        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        await http.get("/sanctum/csrf-cookie");
        const { data: response } = await http.post("api/ana/init", visitorData, config);
        const { visitor_id: responseVisitorId, visitor_visit_id: responseVisitorVisitId } =
            response;
        setVisitorId(responseVisitorId);
        a.setVisitor(responseVisitorId);
        setVisitorVisitId(responseVisitorVisitId);
        a.setVisitorVisit(responseVisitorVisitId);
    };
    const getAndStoreVisitorVisitId = async () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        let params = Object.fromEntries(urlSearchParams.entries());

        const visitorData = {};
        visitorData.fbclid = params.fbclid;
        visitorData.referrer = document.referrer;
        visitorData.full_url = window.location.href;
        visitorData.is_admin = auth.getAdminToken() ? 1 : 0;

        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        await http.get("/sanctum/csrf-cookie");
        const { data: response } = await http.post(
            `api/ana/init/${visitorId}`,
            visitorData,
            config
        );
        const { visitor_visit_id: responseVisitorVisitId } = response;
        setVisitorVisitId(responseVisitorVisitId);
        // console.log(responseVisitorVisitId);
        a.setVisitorVisit(responseVisitorVisitId);
    };
    useEffect(() => {
        if (isAnalytics) {
            if (!uid) {
                let localStorageUid = a.getUid();
                if (localStorageUid) {
                    setUid(localStorageUid);
                } else {
                    getAndStoreUID();
                }
            } else {
                if (!visitorId) {
                    let localStorageVisitorId = a.getVisitor();
                    if (localStorageVisitorId) {
                        setVisitorId(localStorageVisitorId);
                    } else {
                        getAndStoreVistorId();
                    }
                } else {
                    if (!visitorVisitId) {
                        getAndStoreVisitorVisitId();
                    }
                }
            }
        }
    }, [uid, visitorId, visitorVisitId]);
    const [allowedPages, setAllowedPages] = useState(null);

    useEffect(() => {
        let authUser = auth.getUser();
        setUser(authUser ? authUser : {});
        setAdmin(auth.getAdmin());
    }, []);

    useEffect(() => {
        setIsPrepaidChecked(false);
        setToken(auth.getToken());
        if (user.full_name) {
            if (user.full_name.includes("random")) {
                setIsCheckedInvalidName(true);
                setIsValidName(false);
            } else {
                setIsCheckedInvalidName(false);
            }
        } else {
            setIsValidName(false);
            setIsCheckedInvalidName(false);
        }
        if (!auth.getToken()) {
            setIsFreeTrial(false);
            setIsFreeTrialDone(false);
            setFreeTrialTimeLeft(0);
        }
    }, [user, user.full_name]);

    useEffect(() => {
        setAdminToken(auth.getAdminToken());
    }, [admin]);

    const loginUser = async (phone, password) => {
        try {
            const id = toast.loading("يتم الآن تسجيل دخولك");
            await http.get("/sanctum/csrf-cookie");
            phone = parseInt(phone);
            // console.log({ phone, password });
            const result = await http.post("api/auth/login", { phone, password });

            auth.authenticate(result.data.token, result.data.user);
            setUser({
                ...result.data.user,
            });
            toast.update(id, {
                render: "تم تسجيل دخولك بنجاح",
                type: "success",
                isLoading: false,
            });
            setTimeout(() => {
                window.location.href = "/course/6";
            }, 2000);
            // modal.message({
            //     title: "تم تسجيل الدخول بنجاح !",
            //     text: "اضغط حسنًا للإستمرار",
            //     callback: redirect,
            // });
        } catch ({ response }) {}
    };

    useEffect(() => {
        if (enableAutoLogin && !auth.getToken() && !loginAttemp) {
            setLoginAttemp(true);
            const urlSearchParams = new URLSearchParams(window.location.search);
            let params = Object.fromEntries(urlSearchParams.entries());

            if (params.credentials) {
                let { credentials } = params;
                credentials = credentials.split("~");
                if (credentials.length === 2) {
                    let phone = credentials[0];
                    let password = credentials[1];
                    phone = phone.substring(1);
                    password = password.substring(1);
                    loginUser(phone, password);
                }
            }
        }
    }, [loginAttemp]);

    return (
        <AuthContext.Provider
            value={{
                token,
                user,
                setUser,
                admin,
                adminToken,
                setAdmin,
                allowedPages,
                setAllowedPages,
                isFreeTrial,
                setIsFreeTrial,
                freeTrialTimeLeft,
                setFreeTrialTimeLeft,
                isFreeTrialDone,
                setIsFreeTrialDone,
                isFreeTrialMenuClosed,
                setIsFreeTrialMenuClosed,
                prepaidCourses,
                setPrepaidCourses,
                isPrepaidChecked,
                setIsPrepaidChecked,
                isCheckedInvalidName,
                setIsCheckedInvalidName,
                isValidName,
                setIsValidName,
                uid,
                setUid,
                visitorId,
                setVisitorId,
                visitorVisitId,
                setVisitorVisitId,
                isNavigated,
                setIsNavigated,
                setSubscribedCourses,
                subscribedCourses,
                getSubscribedCourses,
                handleSubscribedCourses,
                isSubscribedCoursesFetchingLoading,
                isSubscribedCoursesFetchingError,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;

import React from "react";
import Table from "./Table";
import { printFullDateTime } from "../../utils/time";

const LogginDeviceLimitedAttemptTable = ({ currentDevice, isAdmin = false, ...props }) => {
    let columns = [
        {
            name: "نوع الجهاز",
            reorder: true,
            selector: (row) => row.device_type,
        },
        {
            name: "اسم الجهاز",
            reorder: true,
            selector: (row) => row.device_name,
        },
        {
            name: "نظام التشغيل",
            reorder: true,
            selector: (row) => row.device_platform,
        },
        {
            name: "المتصفح",
            reorder: true,
            selector: (row) => row.device_browser,
        },
    ];
    if (isAdmin) {
        columns = [
            ...columns,
            {
                name: "ال ip",
                reorder: true,
                selector: (row) => row.ip,
            },
            {
                name: "تاريخ محاولة تسجيل الدخول",
                reorder: true,

                selector: (row) => printFullDateTime(row.created_at),
            },
        ];
    }
    return <Table columns={columns} data={[currentDevice]} pagination isExcel={false} {...props} />;
};

export default LogginDeviceLimitedAttemptTable;

import React, { useContext, useEffect, useState } from "react";

import ScrollingContext from "../../context/ScrollingContext";

import { getScrollingPercentage, windowHeight } from "../../services/scrollingServices";

import Waves from "./Waves";

import "./sectionHead.css";
import Button from "./Button";
import CenterIcon from "./CenterIcon";

const SectionHead = ({
    className = "bg-cyan-800 text-slate-100",
    title = "الكورسات",
    callback = () => null,
    withRefreshBtn = false,
}) => {
    const [percentage, setPercentage] = useState(0);
    const { scrolling } = useContext(ScrollingContext);

    useEffect(() => {
        const yearsHeader = document.querySelector(".home-years__head");

        let { percentage: percent } = getScrollingPercentage(
            scrolling,
            yearsHeader.getBoundingClientRect().top + scrolling,
            (windowHeight + yearsHeader.style.height) / 2
        );
        setPercentage(percent);
    }, [scrolling]);

    return (
        <div className={`home-years__head ${className}`}>
            <div className="relative pt-10 px-10 overflow-hidden">
                <div className="home-years__title font-big py-5 font-w-bold relative">
                    <div
                        className="home-years-title-bg__container"
                        style={{ transform: `translateX(${-100 * (1 - percentage)}%)` }}
                    >
                        <div className="home-years-title__bg font-bigmax text-slate-100">
                            {title}
                        </div>
                    </div>

                    <div
                        className={`home-years-title__content ${
                            withRefreshBtn && "flex gap-3 items-center"
                        }  relative z-10`}
                    >
                        {withRefreshBtn && (
                            <Button
                                onClick={() => callback()}
                                className="bg-yellow-500 border-none text-white flex items-center justify-center gap-2 p-4 rounded-full shadow-lg transition-all hover:bg-rose-400 transform hover:scale-105"
                            >
                                <CenterIcon
                                    icon="mingcute:refresh-3-line"
                                    className="text-white font-h2"
                                />
                            </Button>
                        )}
                        {title}
                    </div>
                </div>
            </div>
            {/* <Waves className="fill-primary-container transform translate-y-1/4 scale-y-75 -mt-10" /> */}
            <Waves className="fill-primary-container" />
        </div>
    );
};

export default SectionHead;

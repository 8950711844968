const localStorageService = {
    /**
     * Set data in localStorage with optional expiration time.
     * @param {string} key - The key under which the data is stored.
     * @param {*} value - The data to store (will be JSON-stringified).
     * @param {number} [ttl] - Time to live in milliseconds (optional).
     */
    set: (key, value, ttl) => {
        const item = {
            value: value,
            expiry: ttl ? Date.now() + ttl : null,
        };
        localStorage.setItem(key, JSON.stringify(item));
    },

    /**
     * Get data from localStorage, considering expiration time.
     * @param {string} key - The key of the data to retrieve.
     * @returns {*} The parsed data or null if not found or expired.
     */
    get: (key) => {
        const itemStr = localStorage.getItem(key);

        // If the item doesn't exist, return null
        if (!itemStr) {
            return null;
        }

        try {
            const item = JSON.parse(itemStr);

            // If there is no expiry time, return the value
            if (!item.expiry) {
                return item.value;
            }

            // If the item is expired, remove it and return null
            if (Date.now() > item.expiry) {
                localStorage.removeItem(key);
                return null;
            }

            return item.value;
        } catch (e) {
            // If parsing fails, remove the corrupted item and return null
            localStorage.removeItem(key);
            return null;
        }
    },

    /**
     * Remove an item from localStorage.
     * @param {string} key - The key of the data to remove.
     */
    remove: (key) => {
        localStorage.removeItem(key);
    },

    /**
     * Clear all data from localStorage.
     */
    clear: () => {
        localStorage.clear();
    },

    /**
     * Clear user-specific cached data.
     * @param {string} email - The email of the user.
     */
    clearUserCache: (email) => {
        if (!email) return;

        const keysToRemove = [
            `prepaidCourses_${email}`,
            // `unassignedSubscriptions_${email}`,
            `globalNotifications-${email}`,
            `notifications_${email}`,
            `centerExamResultsToken_${email}`,
            `currentBalance_${email}`,
            `subscribedCourses_${email}`,
        ];

        keysToRemove.forEach((key) => localStorageService.remove(key));
    },

    /**
     * Clear all cache for all user emails based on specified key patterns.
     */
    clearAllUserCaches: () => {
        const keysToRemove = [
            `prepaidCourses_`,
            // `unassignedSubscriptions_`,
            `globalNotifications-`,
            `notifications_`,
            `centerExamResultsToken_`,
            `currentBalance_`,
            `subscribedCourses_`,
        ];

        const allKeys = Object.keys(localStorage);

        allKeys.forEach((key) => {
            if (keysToRemove.some((pattern) => key.startsWith(pattern))) {
                localStorage.removeItem(key);
            }
        });
    },
};

export default localStorageService;

import React, { useState } from "react";
import InputIcon from "../../components/form/elements/InputIcon";
import AdminForm from "../../components/ui/AdminForm";
import auth from "../../services/authServices";
import {
    handleFormErrors,
    handleFormSubmit,
    handleInputChange as handleChange,
    renderInputFields,
} from "../../services/formServices";
import http from "../../services/httpServices";
import modal from "../../services/modalServices";

const ChangeUserFatherPhoneForm = ({ user = { id: 0 }, afterSuccess = () => null }) => {
    const [data, setData] = useState({
        father_phone: "0" + user.father_phone,
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const inputFields = [
        {
            id: "father_phone",
            togglePassword: true,
            placeholder: "رقم هاتف ولي الأمر الجديد",
            icon: <InputIcon icon="ant-design:phone-filled" />,
        },
    ];
    const handleSubmit = (e) => {
        // return;

        handleFormSubmit(e, setIsLoading, setErrors, () => {
            modal.message({
                title: "هل انت متأكد",
                text: `هل انت متأكد من تعديل رقم هاتف ولي الأمر  إلى ${data.father_phone}?`,
                icon: "info",
                // button: "تأكيد",
                buttons: {
                    confirm: "تأكيد",
                    cancel: "إلغاء",
                },
                callback: (e) => {
                    if (e && e !== "cancel") {
                        handleEditFatherPhone();
                    } else {
                        setIsLoading(false);
                    }
                },
            });
        });
    };
    const handleEditFatherPhone = async () => {
        const adminToken = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(adminToken);

        try {
            setIsLoading(false);

            const toSendUser = { ...data };
            toSendUser["phone"] = parseInt(data["phone"]);
            toSendUser["father_phone"] = parseInt(data["father_phone"]);

            const { data: response } = await http.post(
                `/api/users/${user.id}/change_father_phone`,
                toSendUser,
                config
            );

            modal.message({
                title: `تم تعديل رقم الهاتف بنجاح`,
                text: "",
                callback: () => {
                    afterSuccess();
                },
            });
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
        }
    };

    return (
        <AdminForm
            onSubmit={handleSubmit}
            isLoading={isLoading}
            buttonText="تغيير رقم هاتف ولي الأمر"
            className="space-y-4 py-0 pb-16"
        >
            {inputFields.map((input, key) =>
                renderInputFields(
                    key,
                    input.handleChange ? input.handleChange : handleChange,
                    data,
                    setData,
                    errors,
                    input
                )
            )}
        </AdminForm>
    );
};

export default ChangeUserFatherPhoneForm;

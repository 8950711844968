import React from "react";

import Categorie from "./Categorie";

import bag from "../../../assets/bag.svg";
import starsbg from "../../../assets/Group8.png";
import Container from "../../../components/ui/Container";

const CoursesSection = ({ title = false, year = 1 }) => {
    // const { token } = useContext(AuthContext);
    return (
        <>
            <div
                className="relative mb-20 overflow-hidden bg-cyan-900 years-section py-20 rounded-md"
                id="courses"
            >
                <div className="years__head flex-center-x">
                    <div className="years__title text-center flex-center-y h-full pt-5">
                        <div>
                            {title ? title : "الكورسات"}
                            <div className="flex row flex-center-x">
                                <div className="basis-2/3">
                                    <img src={bag} alt="bag" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="years__circle"></div>
                </div>

                <div className="px-10 ">
                    <Container className="bg-outer-container rounded-md clr-text-primary smooth">
                        <Categorie
                            titleFirst="أحدث"
                            titleLast="الكورسات"
                            api={`/api/sellables/year/${year}`}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
};

export default CoursesSection;

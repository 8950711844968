import React from "react";

import logoTextDark from "../assets/navbar/logodark.png";
import logoTextLight from "../assets/navbar/logolight.png";
import NavbarLink from "../layout/navbar/components/NavbarLink";
import DisclosureLink from "../layout/navbar/components/DisclosureLink";
import CenterIcon from "../components/ui/CenterIcon";
import { ReactComponent as RegisterIcon } from "../assets/khaled-imagery/RegisterIcon.svg";
export const navLogoComponent = (darkmode) => (
    <>
        {/* <img
            className="h-12 w-auto block lg:hidden"
            src={logo}
            alt="Workflow"
        /> */}
        <img
            className="h-10 sm:h-14 w-auto"
            src={darkmode ? logoTextDark : logoTextLight}
            alt="Workflow"
        />
    </>
);
export const navbarClassName = (isNavbarHidden, isRTL, scrolling) =>
    ` ${scrolling && "bg-primary-container"}`;
export const NavbarRegisterComponent = () => (
    <NavbarLink
        to="/register"
        className=" bg-redMelon-500 rounded-tr-lg rounded-bl-lg dark:bg-redMelon-500 outline outline-redMelon-500 dark:outline-redMelon-700 outline-offset-4 hover:outline-offset-0 hover:outline border-bluefr-500 "
        defualtHover="hover:dark:border-opacity-100  hover-shadow group"
    >
        <span className="flex-center-both font-h2 mb-0.5">
            <RegisterIcon className="flex-center-both w-7 h-7 smooth fill-white" />
        </span>
        <span className="flex-center-both space-x-1 smooth space-x-reverse text-redMelon-100 font-bold">
            انشئ حسابك
        </span>
    </NavbarLink>
);
export const NavbarLoginComponent = ({ isRTL }) => (
    <NavbarLink
        to="/login"
        className="border bg-redMelon-100 rounded-tr-lg rounded-bl-lg dark:bg-sakrBlue-800 outline outline-redMelon-500 dark:outline-sakrBlue-600 outline-offset-4 hover:outline-offset-0 border-none border-opacity-0"
        defualtHover="hover:dark:border-opacity-100  hover-shadow group"
    >
        <CenterIcon
            icon="line-md:login"
            className="text-redMelon-950 dark:text-sakrBlue-100 smooth font-h2 w-7 h-7 group-hover:rotate-[-360deg]"
            nY="0"
        />

        <span className="flex-center-both space-x-1 space-x-reverse text-redMelon-950 dark:text-sakrBlue-100 smooth font-bold">
            سجل دخولك
        </span>
    </NavbarLink>
);
export const DisclosureLoginComponent = ({ isRTL }) => (
    <DisclosureLink to="/login">
        <CenterIcon icon="ri:door-lock-line" className="text-yellow-400 font-h1" nY="0" />

        <span className="flex-center-both">الدخول إلى حسابك</span>
    </DisclosureLink>
);
export const DisclosureRegisterComponent = () => (
    <DisclosureLink to="/register">
        <CenterIcon icon="ph:user-circle-plus-duotone" className="font-h1 text-rose-400" nY="0" />
        {/* <UserAddIcon className="h-6 w-6 text-yellow-300" /> */}
        <span className="flex-center-both">انشئ حسابك الآن !</span>
    </DisclosureLink>
);

export const domainName = "mahmoud-magdy.com";

import React, { useEffect, useState } from "react";
import InputIcon from "../../components/form/elements/InputIcon";
import http from "../../services/httpServices";
import auth from "../../services/authServices";
import modal from "../../services/modalServices";
import AdminContainer from "../../components/ui/AdminContainer";
import {
    handleFormErrors,
    handleFormSubmit,
    handleInputChange as handleChange,
    renderInputFields,
} from "../../services/formServices";
import AdminForm from "../../components/ui/AdminForm";

const initialState = {
    phone: "",
    father_phone: "",
};
const EditUserData = () => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const inputFields = [
        {
            id: "phone",
            togglePassword: true,
            placeholder: "رقم الهاتف",
            icon: <InputIcon icon="ant-design:phone-filled" />,
        },
        {
            id: "father_phone",
            togglePassword: true,
            placeholder: "رقم هاتف ولي الأمر الجديد",
            icon: <InputIcon icon="ant-design:phone-filled" />,
        },
    ];
    const handleSubmit = (e) => {
        // return;

        handleFormSubmit(e, setIsLoading, setErrors, () => {
            modal.message({
                title: "هل انت متأكد",
                text: `هل انت متأكد من تعديل رقم هاتف ولي الأمر  إلى ${data.father_phone}?`,
                icon: "info",
                // button: "تأكيد",
                buttons: {
                    confirm: "تأكيد",
                    cancel: "إلغاء",
                },
                callback: (e) => {
                    if (e && e !== "cancel") {
                        handleEditFatherPhone();
                    } else {
                        setIsLoading(false);
                    }
                },
            });
        });
    };
    const handleEditFatherPhone = async () => {
        const adminToken = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(adminToken);

        try {
            setIsLoading(false);

            const toSendUser = { ...data };
            toSendUser["phone"] = parseInt(data["phone"]);
            toSendUser["father_phone"] = parseInt(data["father_phone"]);

            const { data: response } = await http.post(
                `/api/users/edit_user_data`,
                toSendUser,
                config
            );

            modal.message({
                title: `تم تعديل رقم الهاتف بنجاح`,
                // text: "",
                // callback: () => {
                //     afterSuccess();
                // },
            });
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
        }
    };

    return (
        <div className="space-y-10">
            <AdminContainer>
                <AdminForm
                    onSubmit={handleSubmit}
                    isLoading={isLoading}
                    buttonText="تغيير رقم هاتف ولي الأمر"
                    // className="space-y-4 py-0 pb-16"
                >
                    {inputFields.map((input, key) =>
                        renderInputFields(
                            key,
                            input.handleChange ? input.handleChange : handleChange,
                            data,
                            setData,
                            errors,
                            input
                        )
                    )}
                </AdminForm>
            </AdminContainer>
        </div>
    );
};

export default EditUserData;

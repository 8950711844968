import React from "react";
import Table from "./Table";
import Button from "./Button";
import LoginDevicesTable from "./LoginDevicesTable";
import LogginDeviceLimitedAttemptTable from "./LogginDeviceLimitedAttemptTable";

const LoginLimitedDevicesErrorInfo = ({ data, currentDevice, back, ...props }) => {
    return (
        <>
            <div className="py-20 space-y-4">
                <div className="rounded-md bg-rose-500 flex-center-both clr-white p-4 text-center">
                    عذرًا لا يمكنك تسجيل الدخول من هذا المتصفح
                    <br />
                    تم تخطي عدد المتصفحات المسموح بها للحساب الواحد
                </div>
                <div className="rounded-md bg-yellow-500 flex-center-both clr-white p-4 text-center">
                    بيانات المتصفح الحالى الذي تحاول الدخول منه
                </div>
                <LogginDeviceLimitedAttemptTable data={[currentDevice]} />

                <div className="rounded-md bg-yellow-500 flex-center-both clr-white p-4 text-center">
                    هذه هي المتصفحات التي تم الدخول منها للحساب
                </div>
                <LoginDevicesTable data={data} currentDevice={currentDevice} isExcel={false} />
                <div className="flex-center-both">
                    <Button color="rose" onClick={back}>
                        اعادة محاولة تسجيل الدخول
                    </Button>
                </div>
            </div>
        </>
    );
};

export default LoginLimitedDevicesErrorInfo;

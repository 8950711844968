import React, { useEffect, useState } from "react";
import InputIcon from "../../components/form/elements/InputIcon";
import AdminForm from "../../components/ui/AdminForm";
import auth from "../../services/authServices";
import {
    handleFormErrors,
    handleFormSubmit,
    handleInputChange as handleChange,
    renderInputFields,
} from "../../services/formServices";
import http from "../../services/httpServices";
import modal from "../../services/modalServices";
import { years } from "../../services/yearSevices";

const ChangeUsersYear = ({ user = { id: 0 }, afterSuccess = () => null }) => {
    const [data, setData] = useState({
        year: user.year,
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const inputFields = [
        {
            id: "year",
            placeholder: "اختر الصف الدراسي",
            type: "select",
            options: years.slice(0, 3),
        },
    ];
    const handleSubmit = (e) => {
        // return;
        setIsLoading(true);

        handleFormSubmit(e, setIsLoading, setErrors, () => {
            modal.message({
                title: " هل انت متأكد من تغيير السنة الدراسية للطالب؟",
                text: `يجب عليك التأكد من السنة الدراسية للطالب`,
                icon: "info",
                buttons: {
                    confirm: "تأكيد",
                    cancel: "إلغاء",
                },
                callback: (e) => {
                    if (e && e !== "cancel") {
                        handleChangeUserYear();
                    } else {
                        setIsLoading(false);
                    }
                },
            });
        });
    };
    const handleChangeUserYear = async () => {
        const adminToken = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(adminToken);

        try {
            const { data: response } = await http.post(
                `/api/users/${user.id}/admin_change_user_year`,
                data,
                config
            );

            modal.message({
                title: `عملية ناجحة`,
                text: "تم اضافة الرسالة بنجاح",
                callback: () => {
                    setData({
                        note: "",
                    });
                    setIsLoading(false);
                    afterSuccess();
                },
            });
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
        }
    };

    useEffect(() => {
        // setData({ note: user.note });
    }, [user]);

    return (
        <AdminForm
            onSubmit={handleSubmit}
            isLoading={isLoading}
            buttonText="تعديل السنة الدراسية"
            className="space-y-4 py-0 pb-16"
        >
            <div className="font-h1 text-center font-w-bold"> تغيير السنة الدراسية</div>
            <div className="pb-4 space-y-5"></div>
            {inputFields.map((input, key) =>
                renderInputFields(
                    key,
                    input.handleChange ? input.handleChange : handleChange,
                    data,
                    setData,
                    errors,
                    input
                )
            )}
        </AdminForm>
    );
};

export default ChangeUsersYear;

import React, { useContext, useState } from "react";

import AuthContext from "../../context/AuthContext";

import "./MainSection.css";

import Container from "../../components/ui/Container";

import bg from "../../assets/mainsectionbg.png";
import aboutbg from "../../assets/Group3.png";
import aboutbgre from "../../assets/Layer14.png";
import bgchem from "../../assets/sakr-bg.png";
import Button from "../../components/ui/Button";
import TextDecore from "../../assets/khaled-imagery/textDecore.png";
import { Link } from "react-router-dom";
import waterMelonicon from "../../assets/khaled-imagery/waterMelonIcon.png";
import { ReactComponent as CommunityIcon } from "../../assets/khaled-imagery/MentorIcon.svg";
import "../../components/ui/Buttons/Btns.css";
import SakrHero from "../../assets/khaled-imagery/SakrHero";

const MainSection = ({ title = false }) => {
    const { token } = useContext(AuthContext);

    const [profileTransform, setProfileTransform] = useState("translate(0,0)");
    const onMouseMove = (e) => {
        let mainSection = document.getElementById("main_section");
        let mainSectionHeight = mainSection.clientHeight;
        let mainSectionWidth = mainSection.clientWidth;

        let ammountToBeMoved = 20;

        let xPercentage = (e.pageX * 2) / mainSectionWidth - 1;
        let yPercentage = (e.pageY * 2) / mainSectionHeight - 1;
        // console.log(xPercentage, yPercentage);

        let x = xPercentage * ammountToBeMoved;
        let y = yPercentage * ammountToBeMoved;

        setProfileTransform(`translate(${x}px,${y}px)`);
    };

    return (
        <>
            <section
                id="main_section"
                className="negative-nav-margin  smooth clr-text-primary relative overflow-hidden"
                onMouseMove={onMouseMove}
            >
                <div className="md:h-screen min-h-screen">
                    {/* <div
                        className="inset-0 w-full h-full z-0 absolute opacity-100"
                        style={{
                            backgroundImage: "url(" + bg + ")",
                            backgroundSize: "cover",
                            backgroundPosition: "center top",
                            // backgroundRepeat: "repeat-y",
                        }}
                    ></div> */}
                    <Container className="h-full relative z-10 font-fs pt-32 md:pt-0 pb-20 md:pb-0">
                        <div className="w-full flex md:flex-row flex-col h-full space-y-20 md:space-y-0 md:px-32">
                            <div className="md:basis-1/2 basis-full h-full flex-center-both">
                                <div className="w-full h-full flex-center-both max-w-xl pt-12">
                                    <div className="w-full h-full flex-center-both">
                                        {/* <img
                                            src={profile}
                                            alt={"profile"}
                                            className="max-h-full smooth"
                                            style={{ transform: profileTransform }}
                                        /> */}
                                        <SakrHero
                                            className="max-h-full smooth"
                                            style={{ transform: profileTransform }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className=" md:basis-1/2 basis-full h-full flex-center-both">
                                <div className="flex flex-col md:items-start items-center justify-center space-y-6 md:space-y-0 font-kuf">
                                    <h2 className="font-big font-rady font-w-bold flex">
                                        <div className="relative text-[#37C87A] text-stroke">
                                            <div className="relative z-1">م /</div>
                                        </div>
                                    </h2>
                                    <h1 className="font-bigmax font-rady text-redMelon-500 text-stroke font-w-bold relative smooth flex">
                                        <div className="relative">
                                            <div className="-top-14 -left-14 w-full h-full z-0 absolute">
                                                <img
                                                    src={TextDecore}
                                                    alt={"bgchem"}
                                                    className="h-full w-full"
                                                />
                                            </div>
                                            <p className="relative z-10">خالد صقر</p>
                                        </div>
                                    </h1>
                                    <div className="flex flex-col font-rady text-center md:items-start items-center justify-center">
                                        <h2 className="font-h1">منصتك في علم الكيمياء ..</h2>
                                        <div className="font-h1 flex">بيتك ومطرحك</div>
                                    </div>
                                    <div className="flex flex-col space-y-5 pt-10">
                                        {!token ? (
                                            <div className="btn-holder">
                                                <Link
                                                    className="btn btn-4 pt-2 pb-1 pr-1 pl-1 min-w-[270px] hover:scale-105 smooth group  hover-border-7"
                                                    to={`${
                                                        token ? "/me/user/courses" : "/register"
                                                    }`}
                                                >
                                                    <span className=" px-5 py-3 font-semibold bg-[#63D497] group-hover:shadow-md smooth text-black flex-center-both gap-4">
                                                        <img
                                                            src={waterMelonicon}
                                                            alt="Sakr-Cta-Icon"
                                                        />
                                                        {token ? <>كورساتي </> : <>سجل معانا </>}
                                                    </span>
                                                </Link>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {/* <div className="btn-holder w-full relative z-10 saturate-50">
                                            <Link
                                                className="btn btn-4 pt-2 pb-1 pr-1 pl-1 min-w-[270px] hover:scale-105 smooth group  hover-border-7"
                                                to={`/community/community_categories`}
                                            >
                                                <span className=" px-5 py-4 font-semibold bg-[#b1f0ce] dark:bg-[#217849] group-hover:shadow-md clr-text-primary smooth flex-center-both gap-4">
                                                    <CommunityIcon className="fill-black dark:fill-white smooth" />
                                                    منتدى طلبة خالد صقر
                                                  
                                                </span>
                                            </Link>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <div className="py-10"></div>
            </section>
        </>
    );
};

export default MainSection;
